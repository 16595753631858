<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div class="blog-contact-cnt">
    <a href="#">
      <div class="nav-item">{{ $t('privacy-policy') }}</div>
    </a>
    <a href="#">
      <div class="nav-item"> {{ $t('return-policy') }}</div>
    </a>
    <a href="#">
      <div class="nav-item">{{ $t('terms-and-conditions') }}</div>
    </a>
    <div class="header-top-buttons mt-3 d-flex">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.06779 2.19487C9.54362 1.49057 10.5807 1.49057 11.0565 2.19486L11.5805 2.97051C11.8885 3.42645 12.4672 3.61448 12.9844 3.42667L13.8643 3.10717C14.6632 2.81707 15.5022 3.42662 15.4731 4.27609L15.4412 5.21162C15.4224 5.76155 15.78 6.25379 16.3089 6.40586L17.2085 6.66455C18.0253 6.89944 18.3458 7.88572 17.823 8.5559L17.2473 9.29396C16.9088 9.72782 16.9088 10.3363 17.2473 10.7701L17.823 11.5082C18.3458 12.1784 18.0253 13.1646 17.2085 13.3995L16.3089 13.6582C15.78 13.8103 15.4224 14.3025 15.4412 14.8525L15.4731 15.788C15.5022 16.6375 14.6632 17.247 13.8643 16.9569L12.9844 16.6374C12.4672 16.4496 11.8885 16.6376 11.5805 17.0936L11.0565 17.8692C10.5807 18.5735 9.54362 18.5735 9.06779 17.8692L8.54377 17.0936C8.23573 16.6376 7.65706 16.4496 7.13985 16.6374L6.26 16.9569C5.46107 17.247 4.62209 16.6375 4.65112 15.788L4.68308 14.8525C4.70187 14.3025 4.34424 13.8103 3.81541 13.6582L2.9158 13.3995C2.09894 13.1646 1.77848 12.1784 2.30127 11.5082L2.87701 10.7701C3.21545 10.3363 3.21546 9.72782 2.87701 9.29396L2.30127 8.5559C1.77848 7.88572 2.09894 6.89944 2.9158 6.66455L3.81541 6.40586C4.34424 6.25379 4.70187 5.76154 4.68308 5.21161L4.65112 4.27609C4.62209 3.42662 5.46107 2.81707 6.25999 3.10717L7.13985 3.42667C7.65706 3.61447 8.23573 3.42645 8.54377 2.97051L9.06779 2.19487Z"
            fill="#5092FF"/>
        <path
            d="M6.61841 10.0695C7.37516 10.7877 8.88867 12.2473 8.88867 12.3396C8.88867 12.4319 11.9668 9.29999 13.5058 7.72247"
            stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <div class="ms-1">
        {{ $t('trusted-agencies') }}
      </div>
    </div>
  </div>
</template>
