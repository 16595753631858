<template>
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4102_22963)">
      <mask id="mask0_4102_22963" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="13" height="13">
        <circle cx="6.5" cy="6.5" r="6.35" fill="#D9D9D9" stroke="#434D56" stroke-width="0.3"/>
      </mask>
      <g mask="url(#mask0_4102_22963)">
        <rect y="4.16667" width="13.2487" height="4.41666" fill="#83D915"/>
        <rect y="8.58334" width="13.2487" height="4.41666" fill="#FB163F"/>
        <rect x="-0.248657" y="-0.249985" width="13.2487" height="4.41666" fill="#F6F6F6"/>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_4102_22963">
        <rect width="13" height="13" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'LangFlagBg',
  props: {},
}
</script>
<style lang="scss" scoped>
@import "~@/assets/css/variables.scss";
</style>