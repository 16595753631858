import Button from "@/components/Common/Buttons/Button/Button";
import ModalCloseBtn from "../ModalCloseBtn";
import Modal from "../Modal";
import InputField from '@/components/Common/Inputs/Input/Input';
import {GoogleLogin} from "vue3-google-login";

export default {
    name: 'LoginModal',
    components: {
        Button,
        ModalCloseBtn,
        InputField,
        Modal,
        GoogleLogin
    },
    data() {
        return {
            email: null,
            password: null,
            formErrors: []
        }
    },
    methods: {
        async googleLoginCallback(response) {
            console.log(response);
            try {
                const result = await this.$store.dispatch("user/authenticateGoogleUser", response)
                const token = result.data.authToken;
                const now = new Date();
                now.setTime(now.getTime() + 1 * 3600 * 1000);
                const expires = now.toUTCString()
                document.cookie = "token=" + token + "; expires=" + expires + "; path=/";
                await this.$store.dispatch("user/loadAuthenticatedUser");
                await this.$store.dispatch("utils/closeModal", 'login');
                this.$router.push({name: 'AgencyDashboard'});
            } catch (err) {
                let message = 'Неуспешна аутентикация! Моля опитайте отново!';
                this.$store.dispatch("utils/openNotificationModal", {
                    message: message,
                    type: 'error'
                });
            }
        },
        async login() {
            this.formErrors = [];

            if (this.email === null || this.email === '') {
                this.formErrors.push('email');
            }

            if (this.password === null || this.password === '' || this.password.length < 6) {
                this.formErrors.push('password');
            }

            if (this.formErrors.length > 0) {
                return;
            }

            try {
                await this.$store.dispatch("user/authenticateUser", {email: this.email, password: this.password,})
                const user = await this.$store.dispatch("user/loadAuthenticatedUser");
                await this.$store.dispatch("utils/closeModal", 'login');
                this.$router.push({name: (user.type === 'vendor' ? 'VendorDashboard' : 'AgencyDashboard')});
            } catch (err) {
                let message = 'Грешно потребителско име или парола';
                if (err.response) {
                    message = err.response.data.message;
                }
                this.$store.dispatch("utils/openNotificationModal", {
                    message: message,
                    type: 'error'
                });
            }
        },
        async openForgottenPasswordModal() {
            await this.$store.dispatch("utils/openModal", 'forgottenPass');
            await this.$store.dispatch("utils/closeModal", 'login');
        }
    }
}