<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{
    'input-field': true,
    'left-inner-addon': true,
 }">

    <input :value="content" @input="onDateChange" type="time"
           v-bind:class="{'form-control': true, 'is-invalid': error}"
    />
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_629_3276)">
        <circle cx="10" cy="10" r="9" stroke="#5E6A74" stroke-width="1.5"/>
        <path d="M10 4V10L13 13" stroke="#5E6A74" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_629_3276">
          <rect width="20" height="20" fill="white"/>
        </clipPath>
      </defs>
    </svg>


  </div>
</template>

