import axios from 'axios';

export default {
	namespaced: true,
	state: {
		item: null
	},
	mutations: {
		SET_AGENCY(state, payload) {
			state.agency = payload;
		},
	},
	actions: {
		async getAgency({ commit }, id) {
			const response = await axios.get('/api/agency/' + id);
			commit('SET_AGENCY', response.data);
			return response.data;
		},
	},
};
