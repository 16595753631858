<template>

  <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.96725 4.8903C8.95789 5.74839 8.738 6.55959 8.39645 7.33797C7.9473 8.36955 7.3344 9.30267 6.63728 10.1795C6.06648 10.8969 5.4489 11.5722 4.77517 12.1958C4.57399 12.3834 4.39152 12.3834 4.19034 12.1958C2.9271 11.0095 1.81826 9.69186 0.976101 8.16792C0.5597 7.41768 0.24623 6.62524 0.0824774 5.77652C-0.366674 3.37574 1.06032 1.11094 3.34818 0.491989C5.78108 -0.164474 8.31692 1.3829 8.85028 3.85871C8.92514 4.19632 8.94853 4.54331 9 4.88561C8.98596 4.88561 8.97661 4.88561 8.96725 4.8903ZM6.97882 4.82934C6.96946 3.45076 5.83255 2.3254 4.47106 2.33947C3.09553 2.35353 1.97733 3.48828 1.99137 4.85747C2.00541 6.23136 3.13296 7.34734 4.48977 7.34266C5.8653 7.32859 6.98818 6.19384 6.97882 4.82934Z" fill="#FF7A00"/>
  </svg>
</template>
<script>
export default {
  name: 'LocationIcon',
}
</script>
<style lang="scss" scoped>
</style>