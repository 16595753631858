<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{
    'label-new-english': language === 'english',
    'label-new-bulgarian': language === 'bulgarian',

  }">
  <div class="label-new label-new-english " v-if="language === 'english'">New</div>
  <div class="label-new label-new-bulgarian " v-if="language === 'bulgarian'">ново</div>
  <div class="label-new-white-text label-new-bulgarian " v-if="language === 'bulgarian-white-text'">НОВО</div>
  </div>
</template>

