<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div>
    <Modal :name="'notification'"
           :is-open="$store.state.utils.modals.notification"
    >
      <div style="width: 420px; max-width: 100%">
        <div class="d-flex justify-center">
          <div class="m-auto">
            <div class="ml-auto">
              <svg v-if="$store.state.utils.notificationModalType ==='success'" width="116" height="120"
                   viewBox="0 0 116 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Group 188">
                  <path id="Ellipse 3"
                        d="M58 2.05308C88.84 2.05308 113.947 27.8462 113.947 59.7964C113.947 91.7467 88.84 117.54 58 117.54C27.16 117.54 2.0531 91.7467 2.05309 59.7964C2.05309 27.8462 27.16 2.05308 58 2.05308Z"
                        stroke="#93C034" stroke-width="4.10619"/>
                  <path id="Vector 26" d="M34.9023 57.188L51.7047 73.9115L80.5838 45.1681" stroke="#93C034"
                        stroke-width="4.10619" stroke-linecap="round"/>
                </g>
              </svg>
              <svg v-if="$store.state.utils.notificationModalType ==='error'" width="104" height="108"
                   viewBox="0 0 104 108" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M52 1.84066C79.6497 1.84066 102.159 24.9655 102.159 53.6106C102.159 82.2556 79.6497 105.38 52 105.38C24.3503 105.38 1.84071 82.2556 1.84071 53.6106C1.8407 24.9655 24.3503 1.84067 52 1.84066Z"
                    stroke="#E54D4D" stroke-width="3.68142"/>
                <path d="M40.4961 41.8762L63.9639 65.344" stroke="#E64C4C" stroke-width="4.69357"
                      stroke-linecap="round"/>
                <path d="M63.9648 41.8762L40.497 65.344" stroke="#E64C4C" stroke-width="4.69357"
                      stroke-linecap="round"/>
              </svg>
              <svg v-if="$store.state.utils.notificationModalType ==='loading'"
                   style="margin: auto; background: none; display: block; shape-rendering: auto;" width="200px"
                   height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <path d="M23 50A27 27 0 0 0 77 50A27 29.5 0 0 1 23 50" fill="#e98033" stroke="none">
                  <animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite"
                                    keyTimes="0;1" values="0 50 51.25;360 50 51.25"></animateTransform>
                </path>
              </svg>
            </div>
          </div>
        </div>
        <div class="text-center mt-4 mb-5 notification-message"
             v-if="$store.state.utils.notificationModalMessage"
            v-html="$t($store.state.utils.notificationModalMessage)">
        </div>
      </div>
    </Modal>
  </div>
</template>