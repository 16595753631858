import axios from 'axios';

export default {
    namespaced: true,
    state: {
        authenticated: false,
        authenticationFailed: false,
        info: {}
    },
    mutations: {
        SET_USER_INFO(state, payload) {
            state.info = payload;
        },
        LOGOUT_USER(state) {
            state.info = {};
            state.authenticated = false;
            document.cookie = 'token=; Max-Age=0'
        },
        LOAD_AUTHENTICATED_USER(state, payload) {
            state.authenticated = payload;
        },
        CLEAN_USER_STATE(state) {
            state.authenticationFailed = false;
            state.authenticated = false;
            state.info = {};
        },
    },
    actions: {
        async getVendorOrders({commit}) {
            const response = await axios.get('/api/user');
            commit('SET_USER_INFO', response.data);
            return response.data;
        },
        async logoutUser({commit}) {
            commit('LOGOUT_USER');
        },
        async loadAuthenticatedUser({commit, state}) {
            if (state.authenticated === true) {
                return state.info;
            }

            try {
                const response = await axios.get('/api/user');
                commit('SET_USER_INFO', response.data);
                commit('LOAD_AUTHENTICATED_USER', true);
                return response.data;
            } catch (error) {
                commit('LOAD_AUTHENTICATED_USER', false);
            }
        },
        async authenticateGoogleUser({commit}, payload) {
            return await axios.post('/api/auth/google', payload);
        },
        async authenticateUser({commit}, payload) {
            commit('CLEAN_USER_STATE', payload);
            await axios.post('/api/auth', payload).then((response) => {
                const token = response.data.authToken;
                const now = new Date();
                now.setTime(now.getTime() + 1 * 3600 * 1000);
                const expires = now.toUTCString()
                document.cookie = "token=" + token + "; expires=" + expires + "; path=/";
                return Promise.resolve(true);
            }).catch((error) => {
                commit('SET_USER_INFO', {});
                commit('LOAD_AUTHENTICATED_USER', false);
                console.log('User authentication failed', error);
                return Promise.reject(error);
            });
        },
        async registerUser({commit}, payload) {
            return await axios.post('/api/user', payload);
        },
        async updateUser({commit}, payload) {
            return await axios.post('/api/user/me', payload);
        },
        async updateVendor({commit}, payload) {
            return await axios.post('/api/vendor/' + payload.id, payload);
        },
        async confirmEmail({commit}, payload) {
            return await axios.post('/api/user/confirmation', payload);
        },
        async requestForgottenPasswordMail({commit}, payload) {
            return await axios.post('/api/user/forgotten-password/request', payload);
        },
        async requestChangePasswordMail({commit}, payload) {
            return await axios.post('/api/user/change-password/request', payload);
        },
        async changePassword({commit}, payload) {
            return await axios.post('/api/user/forgotten-password/confirmation', payload);
        },
        async getUser({commit}, id) {
            const response = await axios.get('/api/user/' + id);
            return response.data;
        },
    }
};