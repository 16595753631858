export default {
    namespaced: true,
    state: {
        favorites: typeof localStorage.getItem('favorites') !== "undefined"
        && localStorage.getItem('favorites') !== null
            ? JSON.parse(localStorage.getItem('favorites'))
            : {}
    },
    mutations: {
        ADD_FAVORITES(state, payload) {
            if (state.favorites.hasOwnProperty(payload.type) === false) {
                state.favorites[payload.type] = {};
            }
            state.favorites[payload.type][payload.id] = payload;
        },
        REMOVE_FROM_FAVORITES(state, payload) {
            if (state.favorites.hasOwnProperty(payload.type) === false) {
                return;
            }

            if (state.favorites[payload.type].hasOwnProperty(payload.id)) {
                delete state.favorites[payload.type][payload.id]
            }
        },
    },
    actions: {
        async addToFavorites({commit, state}, payload) {
            commit('ADD_FAVORITES', payload);
            localStorage.setItem('favorites', JSON.stringify(state.favorites));
        },
        async removeFromFavorites({commit, state}, payload) {
            commit('REMOVE_FROM_FAVORITES', payload);
            localStorage.setItem('favorites', JSON.stringify(state.favorites));
        },
        async isInFavorites({commit, state}, payload) {
            if (state.favorites.hasOwnProperty(payload.type) === false) {
                return false
            }
            return state.favorites[payload.type].hasOwnProperty(payload.id);
        },
        async getFavorites({commit, state}, type) {
            if (state.favorites.hasOwnProperty(type) === false) {
                return [];
            }
            return state.favorites[type];
        },
    }
}