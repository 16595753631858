
<template>
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_444_34243)">
        <path
            d="M5.6549 0.0626452C6.2202 -0.0137768 6.7855 -0.0188716 7.35081 0.0626452C7.35081 1.83054 7.3559 3.59844 7.35081 5.36633C7.35081 5.5956 7.40683 5.65674 7.6411 5.65674C9.4083 5.64655 11.1755 5.65164 12.9427 5.65674C13.0191 6.22226 13.0191 6.78778 12.9427 7.3533C11.1653 7.3533 9.39302 7.3584 7.61563 7.3533C7.40683 7.3533 7.35081 7.40425 7.35081 7.61823C7.36099 9.39632 7.3559 11.1693 7.35081 12.9474C6.7855 13.0238 6.2202 13.0238 5.6549 12.9474C5.6549 11.1693 5.64981 9.39632 5.6549 7.61823C5.6549 7.40935 5.60397 7.3533 5.39008 7.3533C3.61269 7.36349 1.84039 7.3584 0.0630034 7.3533C-0.0133886 6.78778 -0.0184814 6.22226 0.0630034 5.65674C1.84039 5.65674 3.61269 5.65164 5.39008 5.65674C5.59888 5.65674 5.6549 5.60579 5.6549 5.39181C5.64981 3.61372 5.6549 1.83564 5.6549 0.0626452Z"
            fill="#D8242D"/>
        <path
            d="M5.65495 0.0626373C5.65495 1.84072 5.64985 3.61371 5.65495 5.3918C5.65495 5.60578 5.60402 5.65673 5.39012 5.65673C3.61273 5.64654 1.84044 5.65163 0.0630493 5.65673C0.0885133 5.34085 0.185277 5.04026 0.24639 4.72948C0.292225 4.87722 0.414453 4.81099 0.50103 4.81099C1.45338 4.81609 2.41083 4.81609 3.36828 4.81099C3.42939 4.81099 3.50069 4.84156 3.59236 4.73966C2.85391 4.01111 2.11036 3.28255 1.3719 2.55399C1.42792 2.47757 1.47885 2.40625 1.53487 2.32982C2.26823 3.06348 3.02197 3.77675 3.72987 4.53587C4.04562 4.87722 4.38684 4.79061 4.73824 4.79571C4.75352 4.77533 4.76371 4.74985 4.77899 4.72948C3.83172 3.79203 2.88446 2.85968 1.9372 1.92224C2.12054 1.70826 2.30898 1.49937 2.56362 1.36691C3.30207 2.10056 4.04053 2.83421 4.81463 3.60352C4.81463 2.46738 4.81463 1.39748 4.81463 0.332662C4.81463 0.302093 4.79426 0.271524 4.78408 0.240955C5.05909 0.139059 5.35956 0.113585 5.65495 0.0626373Z"
            fill="#F0EEEE"/>
        <path
            d="M12.9377 5.65165C11.1705 5.65165 9.40326 5.64655 7.63605 5.65165C7.40688 5.65165 7.34576 5.5956 7.34576 5.36124C7.35595 3.59335 7.35086 1.82545 7.34576 0.0575562C7.65643 0.108504 7.97218 0.149263 8.27265 0.240969C8.13006 0.276633 8.19117 0.388718 8.18608 0.470235C8.18098 1.42806 8.18098 2.38079 8.18608 3.33861C8.18608 3.40994 8.15552 3.48636 8.24719 3.58825C8.98055 2.84441 9.70882 2.10567 10.432 1.36692C10.5084 1.42296 10.5797 1.47391 10.6561 1.52995C9.92272 2.2636 9.20973 3.02273 8.4509 3.72072C8.10459 4.0366 8.20135 4.37795 8.19117 4.72949C8.21663 4.74477 8.237 4.76006 8.26247 4.77534C9.19445 3.82771 10.1315 2.88008 11.0635 1.92735C11.2774 2.11076 11.4862 2.29927 11.6186 2.55401C10.8853 3.29275 10.1519 4.02641 9.38289 4.80591C10.5186 4.80591 11.5881 4.80591 12.6525 4.80591C12.683 4.80591 12.7136 4.78553 12.7441 4.77534C12.8562 5.05556 12.8867 5.35615 12.9377 5.65165Z"
            fill="#F0EEEE"/>
        <path
            d="M7.35077 12.9372C7.35077 11.1591 7.35586 9.38614 7.35077 7.60805C7.35077 7.39917 7.4017 7.34312 7.61559 7.34312C9.39298 7.35331 11.1653 7.34312 12.9427 7.34312C12.8714 7.61824 12.9121 7.92903 12.7339 8.17867C11.6389 8.17867 10.549 8.17867 9.4541 8.17867C9.43882 8.20415 9.42863 8.23471 9.41335 8.26019C10.1518 8.98875 10.8954 9.71221 11.6338 10.4408C11.5778 10.5172 11.5269 10.5885 11.4708 10.6649C10.7375 9.93128 9.98375 9.21801 9.28094 8.45889C8.96519 8.11753 8.62397 8.20415 8.27257 8.20415C8.25729 8.22962 8.24201 8.25 8.22673 8.27547C9.17399 9.20782 10.1213 10.1453 11.0736 11.0776C10.8903 11.2916 10.7018 11.5005 10.4472 11.6329C9.70874 10.8993 8.97028 10.1656 8.18599 9.38614C8.18599 10.5528 8.18599 11.6431 8.18599 12.7334C7.93135 12.9066 7.62578 12.8608 7.35077 12.9372Z"
            fill="#F0EEEE"/>
        <path
            d="M0.0681152 7.34821C1.8455 7.34821 3.6178 7.3533 5.39519 7.34821C5.60399 7.34821 5.66001 7.39915 5.66001 7.61314C5.64983 9.39122 5.65492 11.1642 5.66001 12.9423C5.385 12.871 5.07943 12.9117 4.82479 12.7334C4.82479 11.638 4.82479 10.5477 4.82479 9.45236C4.79933 9.43707 4.76877 9.42688 4.74331 9.4116C4.01504 10.1503 3.29186 10.8942 2.56359 11.6329C2.4872 11.5769 2.4159 11.5259 2.33951 11.4699C3.07287 10.7362 3.78586 9.97712 4.54469 9.27914C4.891 8.95816 4.79424 8.62191 4.80442 8.27036C4.78405 8.25508 4.75859 8.2398 4.73822 8.22961C3.80114 9.17724 2.86916 10.1249 1.93208 11.0725C1.71818 10.8891 1.50938 10.7006 1.37697 10.4458C2.11033 9.7071 2.84369 8.97345 3.60761 8.19904C2.46683 8.19904 1.39224 8.19904 0.31257 8.19904C0.292199 8.19904 0.266735 8.21942 0.246363 8.22961C0.18525 7.92901 0.124136 7.63861 0.0681152 7.34821Z"
            fill="#F0EEEE"/>
        <path
            d="M4.77893 0.240967C4.78912 0.271536 4.80949 0.302104 4.80949 0.332673C4.80949 1.40258 4.80949 2.47249 4.80949 3.60354C4.03539 2.83422 3.29693 2.10057 2.55847 1.36692C3.13396 0.857438 3.81639 0.55175 4.53448 0.307199C4.61596 0.281725 4.69745 0.261346 4.77893 0.240967Z"
            fill="#2B5AA9"/>
        <path
            d="M10.4421 1.36692C9.7138 2.10566 8.98553 2.84441 8.25726 3.58825C8.1605 3.48636 8.19615 3.40484 8.19615 3.33861C8.19106 2.38078 8.19106 1.42806 8.19615 0.470233C8.19615 0.388716 8.14013 0.27663 8.28273 0.240967C8.97535 0.449854 9.62723 0.750447 10.2231 1.17332C10.2995 1.22936 10.3962 1.27012 10.4421 1.36692Z"
            fill="#2B5AA9"/>
        <path
            d="M1.37185 2.554C2.1103 3.28256 2.84876 4.00602 3.59231 4.73458C3.50064 4.83647 3.42934 4.8059 3.36823 4.8059C2.41078 4.811 1.45843 4.811 0.500978 4.8059C0.4144 4.8059 0.292173 4.87213 0.246338 4.72439C0.460236 4.0264 0.760711 3.37426 1.17832 2.77817C1.23434 2.70175 1.27508 2.60495 1.37185 2.554Z"
            fill="#2B5AA9"/>
        <path
            d="M12.7594 4.77024C12.7288 4.78043 12.6983 4.80081 12.6677 4.80081C11.5982 4.80081 10.5287 4.80081 9.39813 4.80081C10.1671 4.0264 10.9005 3.28765 11.6339 2.5489C12.189 3.20104 12.5251 3.96016 12.7594 4.77024Z"
            fill="#2B5AA9"/>
        <path
            d="M0.246338 8.22451C0.266709 8.21432 0.292173 8.19394 0.312544 8.19394C1.39222 8.19394 2.4668 8.19394 3.60759 8.19394C2.83857 8.96835 2.10521 9.702 1.37694 10.4407C0.86766 9.86503 0.562092 9.18233 0.317637 8.46396C0.28708 8.38754 0.266709 8.30602 0.246338 8.22451Z"
            fill="#2B5AA9"/>
        <path
            d="M8.18091 12.7283C8.18091 11.638 8.18091 10.5477 8.18091 9.38103C8.9652 10.1605 9.70366 10.8942 10.4421 11.6278C9.8157 12.1781 9.07215 12.5092 8.27767 12.7385C8.25221 12.7487 8.21656 12.7334 8.18091 12.7283Z"
            fill="#2B5AA9"/>
        <path
            d="M11.6287 10.4408C10.8903 9.7122 10.1467 8.98874 9.40826 8.26018C9.42354 8.23471 9.43373 8.20414 9.44901 8.17867C10.544 8.17867 11.6338 8.17867 12.7288 8.17867C12.7339 8.20923 12.7491 8.24999 12.7441 8.27547C12.5098 9.07025 12.1787 9.809 11.6287 10.4408Z"
            fill="#2B5AA9"/>
        <path
            d="M2.55847 11.6278C3.28674 10.8891 4.00992 10.1453 4.73819 9.40651C4.76366 9.42179 4.79421 9.43198 4.81968 9.44727C4.81968 10.5426 4.81968 11.6329 4.81968 12.7283C4.78912 12.7334 4.74838 12.7487 4.72291 12.7436C3.93353 12.5092 3.18998 12.1781 2.55847 11.6278Z"
            fill="#2B5AA9"/>
        <path
            d="M11.0737 1.93244C10.1417 2.88008 9.20461 3.82771 8.27263 4.78044C8.24716 4.76515 8.22679 4.74987 8.20133 4.73458C8.20642 4.38304 8.11475 4.04679 8.46106 3.72581C9.21989 3.02273 9.93288 2.2687 10.6662 1.53505C10.819 1.64714 10.9769 1.75922 11.0737 1.93244Z"
            fill="#D8222C"/>
        <path
            d="M1.93718 1.92224C2.88444 2.85968 3.83171 3.79203 4.77897 4.72948C4.76369 4.74986 4.7535 4.77533 4.73822 4.79571C4.38682 4.79061 4.0507 4.88232 3.72985 4.53587C3.02704 3.77675 2.27331 3.06348 1.53485 2.33492C1.65199 2.18208 1.76403 2.02414 1.93718 1.92224Z"
            fill="#D8222C"/>
        <path
            d="M11.0686 11.0725C10.1213 10.1402 9.17403 9.20272 8.22168 8.27038C8.23696 8.2449 8.25224 8.22452 8.26751 8.19905C8.61892 8.20414 8.95504 8.11244 9.27589 8.45379C9.9787 9.21291 10.7324 9.92619 11.4658 10.6598C11.3487 10.8178 11.2417 10.9757 11.0686 11.0725Z"
            fill="#D8222C"/>
        <path
            d="M1.927 11.0674C2.86408 10.1198 3.79606 9.17215 4.73314 8.22452C4.75351 8.2398 4.77897 8.25509 4.79934 8.26528C4.78916 8.61682 4.88592 8.95307 4.53961 9.27405C3.77569 9.97713 3.06779 10.7312 2.33443 11.4648C2.18673 11.3476 2.02886 11.2355 1.927 11.0674Z"
            fill="#D8222C"/>
      </g>
      <defs>
        <clipPath id="clip0_444_34243">
          <rect width="13" height="13" fill="white"/>
        </clipPath>
      </defs>
    </svg>
</template>
<script>
export default {
  name: 'LangFlagFb',
  props: {

  },
}
</script>
<style lang="scss" scoped>
@import "~@/assets/css/variables.scss";
</style>