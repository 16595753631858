import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import store from './store';
import VueCookies from 'vue-cookies';
import {i18n} from './i18n';
import vue3GoogleLogin from 'vue3-google-login'
import 'bootstrap/dist/css/bootstrap.min.css';

axios.defaults.withCredentials = true;
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response.status === 401 || error.response.status === 403) {
        document.cookie = 'token=; Max-Age=0'
    }
    return Promise.reject(error);
});

const app = createApp(App);
app.use(store)
    .use(i18n)
    .use(router, axios, VueCookies)
    .use(vue3GoogleLogin, {
        clientId: '409726691484-90e7dpu3k8j7bt3303d7thn9nrt1b44c.apps.googleusercontent.com'
    })
    .mount('#app');
