import Button from "@/components/Common/Buttons/Button/Button";
import ModalCloseBtn from "../ModalCloseBtn";
import Modal from "../Modal";


export default {
    name: 'NotificationModal',
    components: {
        ModalCloseBtn,
        Modal
    },
    props: {
        theme: {
            type: String,
            default: 'regular'
        },
    },
    data() {
        return {
            isMobileMenuOpen: false
        }
    },
    mounted() {
        let vm = this;
    },
    methods: {
        closeModal() {
            this.$store.dispatch("utils/closeNotificationModal");
        },
    }
}