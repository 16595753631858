<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>

  <Modal :name="'signUp'"
         :is-open="$store.state.utils.modals.signUp"
         :title="$t('sign-up')"
  >
    <div style="width: 420px; max-width: 100%">
      <div class="mb-3">
        <InputField :label="$t('your-name')" inputtype="text" v-model="form.name" :content="form.name" size="small"
                    :error="fieldErrors.includes('name')"/>
      </div>
      <div class="mb-3">
        <InputField :label="$t('your-phone')" inputtype="text" v-model="form.phone" :content="form.phone" size="small"
                    :error="fieldErrors.includes('phone')"/>
      </div>
      <div class="mb-3">
        <InputField :label="$t('your-email')" inputtype="text" v-model="form.email" :content="form.email" size="small"
                    :error="fieldErrors.includes('email')"/>
      </div>
      <div class="mb-3">
        <InputField :label="$t('enter-strong-password')" inputtype="password" v-model="form.password"
                    :content="form.password"
                    size="small" :error="fieldErrors.includes('password')"/>
      </div>
      <div class="mb-4">
        <InputField :label="$t('confirm-password')" size="small" inputtype="password"
                    v-model="confirmPassword"
                    :content="confirmPassword"
                    :error="fieldErrors.includes('confirmPassword')"
        />
      </div>
      <div class="d-flex radio-checkbox-options mt-4">
        <CheckBox v-model="termsFlag"/>
        <div class="option-select-label">
          {{ $t('read-and-agreed-terms') }}
        </div>
      </div>
      <div class="d-flex radio-checkbox-options mt-3">
        <CheckBox v-model="form.optMarketingFlag"/>
        <div class="option-select-label">{{ $t('subscribe-for-best-offers') }}</div>
      </div>
      <div class="mb-2 mt-4">
        <Button @click="registerUser()" :theme="'solid-green'" :size="'medium'" :text="$t('sign-up')"/>
      </div>
    </div>
  </Modal>

</template>
