import Button from "@/components/Common/Buttons/Button/Button";
import ModalCloseBtn from "../ModalCloseBtn";
import InputField from '@/components/Common/Inputs/Input/Input';
import CheckBox from '@/components/Common/Inputs/CheckBox/CheckBox';
import Modal from './../Modal';

export default {
    name: 'CoordinatesGuideModal',
    components: {
        Button,
        ModalCloseBtn,
        InputField,
        Modal,
        CheckBox
    },
    data() {
        return {

        }
    },
    methods: {
        closeModal() {
            this.$store.dispatch("utils/closeModal", this.$props.name);
        },
    }
}