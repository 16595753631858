import Button from "@/components/Common/Buttons/Button/Button";
import ModalCloseBtn from "../ModalCloseBtn";
import InputField from '@/components/Common/Inputs/Input/Input';
import DateInput from '@/components/Common/Inputs/DateInput/DateInput';
import TimeInput from '@/components/Common/Inputs/TimeInput/TimeInput';
import Modal from './../Modal';

export default {
    name: 'PropertyViewRequestModal',
    components: {
        Button,
        ModalCloseBtn,
        InputField,
        Modal,
        DateInput,
        TimeInput,
    },
    data() {
        return {
            form: {
                name: null,
                phone: null,
                email: null,
                date: null,
                time: null,
            },
            formErrors: []
        }
    },
    mounted() {
    },
    methods: {
        async sendViewRequest() {
            if (!this.validateFields()) {
                return;
            }


            try {
                this.$store.dispatch('utils/closeModal', 'propertyViewRequest')
                await this.$store.dispatch('utils/openNotificationModal', {
                    message: "Изпращане на заявката ...",
                    type: 'loading'
                });
                let viewDateTime = this.form.date + ' ' + this.form.time;
                await this.$store.dispatch('propertyView/addViewRequest', {
                    propertyId: this.$route.params.id,
                    contactName: this.form.name,
                    contactPhone: this.form.phone,
                    contactEmail: this.form.email,
                    dateTime: viewDateTime,
                });
                await this.$store.dispatch('utils/openNotificationModal', {
                    message: "Заявката за оглед беше изпратена успешно!<br>Очаквайте обаждане от продавач за потвърждение.",
                    type: 'success'
                });
            } catch (err) {
                let error = "Грешка! Моля опитайте отново";
                if ('response' in err) {
                    error = err.response.data.message;
                }
                await this.$store.dispatch('utils/openNotificationModal', {
                    message: error,
                    type: 'error'
                });
            } finally {
                this.formErrors = [];
                this.form = {
                    name: null,
                    phone: null,
                    email: null,
                    date: null,
                    time: null,
                };
            }
        },
        validateFields() {
            this.formErrors = [];
            if (this.form.name === null || this.form.name === '') {
                this.formErrors.push('name');
            }
            if (this.form.phone === null || this.form.phone === '') {
                this.formErrors.push('phone');
            }
            const mailRegex = /\S+@\S+\.\S+/;
            if (this.form.email === null || this.form.email === '' || !mailRegex.test(this.form.email)) {
                this.formErrors.push('email');
            }
            if (this.form.date === null || this.form.date === '') {
                this.formErrors.push('date');
            }
            if (this.form.time === null || this.form.time === '') {
                this.formErrors.push('time');
            }
            return this.formErrors.length === 0;
        },
        setFieldsErrors(err) {
            let vm = this;
            vm.formErrors = [];
            err.response.data.errors.forEach((item) => {
                vm.formErrors.push(item.property);
            });
        },
    }
}