<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{
    'input-field': true,
    'left-inner-addon': true,
  }">

    <input :value="content"
           @input="onDateChange"
           type="date"
           v-bind:class="{'form-control': true, 'is-invalid': error}"
           :min="(new Date().toISOString().split('T')[0])"
    />
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.09277 8.40421H18.9167" stroke="#5E6A74" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round"/>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M4.80811 12.3097C4.80811 11.8955 5.14389 11.5597 5.55811 11.5597H5.56737C5.98158 11.5597 6.31737 11.8955 6.31737 12.3097C6.31737 12.7239 5.98158 13.0597 5.56737 13.0597H5.55811C5.14389 13.0597 4.80811 12.7239 4.80811 12.3097ZM10.005 11.5597C9.59074 11.5597 9.25495 11.8955 9.25495 12.3097C9.25495 12.7239 9.59074 13.0597 10.005 13.0597H10.0142C10.4284 13.0597 10.7642 12.7239 10.7642 12.3097C10.7642 11.8955 10.4284 11.5597 10.0142 11.5597H10.005ZM14.4425 11.5597C14.0282 11.5597 13.6925 11.8955 13.6925 12.3097C13.6925 12.7239 14.0282 13.0597 14.4425 13.0597H14.4517C14.8659 13.0597 15.2017 12.7239 15.2017 12.3097C15.2017 11.8955 14.8659 11.5597 14.4517 11.5597H14.4425ZM14.4425 15.4462C14.0282 15.4462 13.6925 15.782 13.6925 16.1962C13.6925 16.6104 14.0282 16.9462 14.4425 16.9462H14.4517C14.8659 16.9462 15.2017 16.6104 15.2017 16.1962C15.2017 15.782 14.8659 15.4462 14.4517 15.4462H14.4425ZM9.25495 16.1962C9.25495 15.782 9.59074 15.4462 10.005 15.4462H10.0142C10.4284 15.4462 10.7642 15.782 10.7642 16.1962C10.7642 16.6104 10.4284 16.9462 10.0142 16.9462H10.005C9.59074 16.9462 9.25495 16.6104 9.25495 16.1962ZM5.55811 15.4462C5.14389 15.4462 4.80811 15.782 4.80811 16.1962C4.80811 16.6104 5.14389 16.9462 5.55811 16.9462H5.56737C5.98158 16.9462 6.31737 16.6104 6.31737 16.1962C6.31737 15.782 5.98158 15.4462 5.56737 15.4462H5.55811Z"
            fill="#5E6A74"/>
      <path d="M14.0438 1V4.29078" stroke="#5E6A74" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M5.96564 1V4.29078" stroke="#5E6A74" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M14.2383 2.57922H5.77096C2.83427 2.57922 1 4.21516 1 7.22225V16.2719C1 19.3263 2.83427 21 5.77096 21H14.229C17.175 21 19 19.3546 19 16.3475V7.22225C19.0092 4.21516 17.1842 2.57922 14.2383 2.57922Z"
            stroke="#5E6A74" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
</template>

