
export default {
    name: 'SearchRoommateButtonMobile',
    props: {
        logged: {
            type: String,
            default: 'regular'
        }
    },
    components: {

    },
    mounted() {
    }
}