<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>

  <Modal :name="'forgottenPass'"
         :is-open="$store.state.utils.modals.forgottenPass"
         :title="'Забравена парола'"
  >
    <div>
      <div class="mb-3">
        <InputField label="E-mail" inputtype="text" v-model="form.email" :content="form.email"
                    :error="fieldErrors.includes('email')"/>
      </div>
      <div class="mb-2 mt-4">
        <Button @click="requestChangePassEmail()" :theme="'solid-green'" :size="'medium'" :text="'Заявка за смяна на парола'"/>
      </div>
    </div>
  </Modal>

</template>