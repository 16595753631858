<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <Modal :name="'propertyViewRequest'"
         :is-open="$store.state.utils.modals.propertyViewRequest"
         :title="'Заявка за оглед'"
  >
    <div style="width: 420px; max-width: 100%">
      <div class="mb-2">
        <InputField :label="'Име'" :inputtype="'text'" v-model="form.name" :content="form.name"
                    :error="formErrors.includes('name')"/>
      </div>
      <div class="mb-2">
        <InputField :label="'Телефон'" :inputtype="'text'" v-model="form.phone" :content="form.phone"
                    :error="formErrors.includes('phone')"/>
      </div>
      <div>
        <div style="display: flex;margin-bottom: 0.75em;">
          <DateInput @selectedDate="(val)=>form.date=val" v-model="form.date" :content="form.date"
                     :error="formErrors.includes('date')"/>
          <TimeInput @selectedDate="(val)=>form.date=val" v-model="form.time" :content="form.time"
                     :error="formErrors.includes('time')"/>
        </div>
      </div>
      <div class="mb-2">
        <InputField :label="'E-mail'" :inputtype="'text'" v-model="form.email" :content="form.email"
                    :error="formErrors.includes('email')"/>
      </div>
      <div class="mb-2">
        <Button @click="sendViewRequest()" :theme="'solid-green'" :size="'medium'" :text="'Изпрати'"/>
      </div>
    </div>
  </Modal>

</template>