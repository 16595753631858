<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div class="blog-contact-cnt">
    <a href="#">
      <div class="nav-item">{{ $t('blog-button') }}</div>
    </a>
    <a href="#">
      <div class="nav-item">{{ $t('contact-us-button') }}</div>
    </a>
  </div>
</template>
