import axios from 'axios';
export default {
	namespaced: true,
	state: {
		current: null,
	},
	mutations: {
		SET_VENDOR(state, payload) {
			state.current = payload;
		},
	},
	actions: {
		async getVendor({ commit }, id) {
			const response = await axios.get('/api/vendor/' + id);
			commit('SET_VENDOR', response.data);

			return response.data;
		},
	},
};
