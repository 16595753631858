
export default {
    name: 'RoomsNavMobile',
    props: {
        usertype: {
            type: String,
            default: ''
        }
    },
    components: {

    },
    mounted() {
    }
}