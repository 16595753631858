<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div class="search-roommate-cnt">
    <div class="d-flex search-roommate">
      <div class="me-auto">
        <router-link :to="{name: 'SearchRoommateResult'}">
          {{ $t('search-roommate') }}
        </router-link>
      </div>
      <div>
        <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_40_3976)">
            <path
                d="M1 6.25C0.585787 6.25 0.25 6.58578 0.25 7C0.25 7.41421 0.585787 7.75 1 7.75L1 6.25ZM17 7.75C17.4142 7.75 17.75 7.41421 17.75 7C17.75 6.58579 17.4142 6.25 17 6.25L17 7.75ZM1 7.75L17 7.75L17 6.25L1 6.25L1 7.75Z"
                fill="#434D56"/>
            <path
                d="M10.4697 12.4697C10.1768 12.7626 10.1768 13.2374 10.4697 13.5303C10.7626 13.8232 11.2374 13.8232 11.5303 13.5303L10.4697 12.4697ZM17 7L17.5303 7.53033C17.8232 7.23744 17.8232 6.76256 17.5303 6.46967L17 7ZM11.5303 0.46967C11.2374 0.176776 10.7626 0.176776 10.4697 0.46967C10.1768 0.762562 10.1768 1.23744 10.4697 1.53033L11.5303 0.46967ZM11.5303 13.5303L17.5303 7.53033L16.4697 6.46967L10.4697 12.4697L11.5303 13.5303ZM17.5303 6.46967L11.5303 0.46967L10.4697 1.53033L16.4697 7.53033L17.5303 6.46967Z"
                fill="#434D56"/>
          </g>
          <defs>
            <clipPath id="clip0_40_3976">
              <rect width="18" height="14" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  </div>
</template>
