<template>
  <ComingSoonPage/>
  <Header/>
  <div class="router-container">
    <router-view :key="$route.path"/>
  </div>
  <MarketplaceRoomsModal v-if="$store.state.utils.isMarketplaceRoomsModalOpen"/>
</template>

<script>
import Footer from "@/components/Footer/Footer.vue";
import Header from "@/components/Header/HeaderDesktop/Header.vue";
import {setLang} from "@/i18n";
import MarketplaceRoomsModal from "./components/Components/MarketplaceRoomsModal/MarketplaceRoomsModal";
import NotificationModal from "./components/Modal/NotificationModal";
import LoginModal from "./components/Modal/LoginModal";
import ComingSoonPage from "./views/ComingSoonPage/ComingSoonPage.vue";

export default {
  name: 'App',
  components: {LoginModal, NotificationModal, Header, Footer, MarketplaceRoomsModal},
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Sakvartirantite.com',
    // all titles will be injected into this template
    titleTemplate: 'Sakvartirantite.com'
  },
  data() {
      return {
          resizeListener: null,
      }
  },
  watch:{
    async $route (to, from) {
      await this.$store.dispatch('utils/closeModal', 'mobileMenu');
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  },
  async created() {
    // set default lang
    let defaultLang = window.localStorage.getItem('defaultLang') || 'bg';
    if(defaultLang ){
      setLang(defaultLang)
    }

    if (location.host.includes('localhost') === false && this.$route.name !== 'ComingSoonPage') {
      this.$router.push({name: 'ComingSoonPage'});
    }

    await this.$store.dispatch("user/loadAuthenticatedUser");

    const myDiv = document.querySelector('div');
    myDiv.addEventListener('click', function(e) {
      // specify the action to take when the div is clicked
      console.log(e.target);
    });
  },
}
</script>

<style lang="scss">
@import "~@/assets/css/variables.scss";


  html {
    height: 100%;
    width: 100%;
    -webkit-overflow-scrolling: touch;
  }

  body {
    height: 100%;
    width: 100%;
    overflow: auto;
    padding: 0;
    margin: 0;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
  }

  .mt-6 {
    margin-top:4rem!important
  }
  .mt-7 {
    margin-top:5rem!important
  }
  .pt-7 {
    padding-top:5rem!important
  }
  body {
    font-family: $font-regular;
  }

  #app {
    font-family: $font-regular;
    color: #2c3e50;
  }

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.container-fluid {

}

.svg-icon {
  width: 2em;
  height: 2em;
}

.svg-icon path,
.svg-icon polygon,
.svg-icon rect {
  fill: #4c4d4f;
}

.svg-icon circle {
  stroke: #4c4d4f;
  stroke-width: 1;
}
.border-bottom {
  border-bottom: 1px solid #ccc;
}
</style>
