<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>

  <Modal :name="'coordinatesGuide'"
         :is-open="$store.state.utils.modals.coordinatesGuide"
         :title="$t('how-to-find-coordinates-title')"
  >
    <div class="coordinates-modal-content">
      <div class="desktop-guide">
        <div> {{ $t('desktop-device') }}</div>
        <ol>
          <li>{{ $t('desktop-step-one') }}  <a id="google-maps-link" href="https://www.google.com/maps" target="_blank">{{ $t('google-maps')}} </a> </li>
          <li>{{ $t('desktop-step-two') }}
            <ul>
              <li> {{ $t('desktop-step-two-substep')}}</li>
            </ul>
          </li>
          <li>{{ $t('desktop-step-three') }}</li>
        </ol>
      </div>
      <div class="mobile-guide">
        <div> {{ $t('mobile-device') }}</div>
        <ol>
          <li>{{ $t('desktop-step-one') }}  <a id="google-maps-link" href="https://www.google.com/maps" target="_blank">{{ $t('google-maps')}} </a> </li>
          <li>{{ $t('mobile-step-two') }}</li>
          <li>{{ $t('mobile-step-three') }}</li>
        </ol>
      </div>
      <div  @click="closeModal()">
        <Button :text="$t('understood')" :theme="'solid-green'" :size="'medium'"/>
      </div>
      <div class="more-info-contact-support"> {{ $t('for-more-info-contact-support') }}</div>
    </div>

  </Modal>

</template>