import Button from '@/components/Common/Buttons/Button/Button';
import {setLang} from '@/i18n';
import LangFlagGb from '@/components/Common/Icons/LangFlagGb';
import LangFlagBg from '@/components/Common/Icons/LangFlagBg';
import ProfileIcon from '@/components/Common/Icons/ProfileIcon';
import UserTypeMobile from "@/components/Header/HeaderMobile/UserTypeMobile/UserTypeMobile";
import UserNavMobile from "@/components/Header/HeaderMobile/UserNavMobile/UserNavMobile";
import SearchRoommateButtonMobile
    from "@/components/Header/HeaderMobile/SearchRoommateButtonMobile/SearchRoommateButtonMobile";
import RoomsNavMobile from "@/components/Header/HeaderMobile/RoomsNavMobile/RoomsNavMobile";
import BlogContactsNavMobile from "@/components/Header/HeaderMobile/BlogContactsNavMobile/BlogContactsNavMobile";
import PolicyNavMobile from "@/components/Header/HeaderMobile/PolicyNavMobile/PolicyNavMobile";
import NotificationModal from "../../Modal/NotificationModal/NotificationModal";
import LoginModal from "../../Modal/LoginModal/LoginModal";
import SignUpModal from "../../Modal/SignUpModal/SignUpModal";
import PassChangeModal from "../../Modal/PassChangeModal/PassChangeModal";
import RoommateOfferMessageModal from "../../Modal/RoommateOfferMessageModal/RoommateOfferMessageModal";
import ForgottenPassModal from "../../Modal/ForgottenPassModal/ForgottenPassModal";
import PropertyViewRequestModal from "../../Modal/PropertyViewRequestModal/PropertyViewRequestModal";
import CreditBuyModal from "../../Modal/CreditBuyModal/CreditBuyModal";
import CoordinatesGuideModal from "../../Modal/CoordinatesGuideModal/CoordinatesGuideModal";

export default {
    name: 'Header',
    components: {
        Button,

        ProfileIcon,
        LangFlagGb,
        LangFlagBg,
        NotificationModal,
        LoginModal,
        SignUpModal,
        PassChangeModal,
        ForgottenPassModal,
        PropertyViewRequestModal,
        RoommateOfferMessageModal,
        CreditBuyModal,
        UserTypeMobile,
        UserNavMobile,
        SearchRoommateButtonMobile,
        RoomsNavMobile,
        BlogContactsNavMobile,
        PolicyNavMobile,
        CoordinatesGuideModal
    },
    computed: {
        user() {
            return this.$store.state.user.info;
        },
        modals() {
            return this.$store.state.utils.modals;
        },
        isUserAuthenticated() {
            return this.$store.state.user.authenticated;
        },
    },
    methods: {
        setLanguage(lang) {
            setLang(lang);
        },
        async openCart() {
            if (Object.keys(this.$store.state.cart.products).length > 0) {
                this.$router.push({name: 'Cart'});
                return;
            }
            await this.$store.dispatch('utils/openNotificationModal', {
                type: 'error',
                message: 'Вашата количка е празна! Моля добавете продукти преди да пристъпите към плащане'
            });
        },
        toggleMobileMenu() {
            this.$store.dispatch('utils/toggleModal', 'mobileMenu');
        },
        activateTranslationsDebugMode() {
            this.setLanguage('debug');
            window.location.reload();
        }
    },
};
