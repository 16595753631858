

export default {
	namespaced: true,
	state: {
		modals: {
			signUp: false,
			login: false,
			passChange: false,
			notification: false,
			propertyViewRequest: false,
			roommateOfferMessage: false,
			creditBuy: false,
			coordinatesGuide: false,
			productImport: false,
		},
		isAdvancedSearchOpen: false,
		isMarketplaceRoomsModalOpen: false,
		properties: [],
		receivedToken: '',

		//notificationModal
		notificationModalMessage: null,
		notificationModalType: null,
	},
	mutations: {
		OPEN_MODAL(state, name) {
			Object.keys(state.modals).forEach((key) => {
				state.modals[key] = false;
			});
			state.modals[name] = true;
		},
		TOGGLE_MODAL(state, name) {
			state.modals[name] = !state.modals[name];
		},
		CLOSE_MODAL(state, name = null) {
			if (!name) {
				Object.keys(state.modals).forEach((key) => {
					state.modals[key] = false;
				});
				return;
			}
			state.modals[name] = false;
		},
		OPEN_NOTIFICATION_MODAL(state, payload) {
			state.modals.notification = true;
			state.notificationModalMessage = payload.message;
			state.notificationModalType = payload.type;
		},
		CLOSE_NOTIFICATION_MODAL(state) {
			state.modals.notification = false;
			state.notificationModalMessage = null;
			state.notificationModalType = null;
		},
		TOGGLE_ADVANCED_SEARCH(state) {
			state.isAdvancedSearchOpen = !state.isAdvancedSearchOpen;
		},
		TOGGLE_MARKETPLACE_ROOMS_MODAL(state) {
			state.isMarketplaceRoomsModalOpen = !state.isMarketplaceRoomsModalOpen;
		},
		CLOSE_MARKETPLACE_ROOMS_MODAL(state) {
			state.isMarketplaceRoomsModalOpen = false;
		},
		ADD_TOKEN(state, token) {
			state.receivedToken = token;
		},
	},
	actions: {
		async openModal({ commit }, name) {
			commit('OPEN_MODAL', name);
		},
		async closeModal({ commit }, name = null) {
			commit('CLOSE_MODAL', name);
		},
		async toggleModal({ commit }, name) {
			commit('TOGGLE_MODAL', name);
		},
		async openNotificationModal({ commit }, payload) {
			commit('OPEN_NOTIFICATION_MODAL', payload);
		},
		async closeNotificationModal({ commit }) {
			commit('CLOSE_NOTIFICATION_MODAL');
		},
		async toggleAdvancedSearch({ commit }, {}) {
			commit('TOGGLE_ADVANCED_SEARCH');
		},
		async toggleMarketplaceRoomsModal({ commit }) {
			commit('TOGGLE_MARKETPLACE_ROOMS_MODAL');
		},
		async closeMarketplaceRoomsModal({ commit }) {
			commit('CLOSE_MARKETPLACE_ROOMS_MODAL');
		},
		async addToken({ commit }, token) {
			commit('ADD_TOKEN', token);
		},
	},
};
