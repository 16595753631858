
export default {
    name: 'UserTypeMobile',
    props: {
        logged: {
            type: String,
            default: 'regular'
        }
    },
    computed: {
        user() {
            return this.$store.state.user.info;
        },
    },
    components: {

    },
    mounted() {
    }
}