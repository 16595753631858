export default {
	name: 'Dropdown',
	props: {
		size: {
			type: String,
			default: 'medium',
		},
		label: {
			type: String,
			default: '',
		},
		options: {
			type: Object,
		},
		selectedProp: {
			type: Number,
			default: null
		},
		error: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		handleInput(e) {
			this.$emit('update:modelValue', e.target.value);
		},
	},
};
