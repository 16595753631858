<template>


  <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_246_33456)">
      <path d="M6.90479 3.91448C6.79298 2.74007 7.46382 2.05499 8.31526 1.49448C9.40752 0.773815 10.6288 0.435727 11.8845 0.222197C14.3528 -0.17817 16.7953 -0.0536115 19.1691 0.800506C19.9689 1.08521 20.7085 1.48558 21.3192 2.11727C21.8008 2.61551 22.0416 3.20271 21.9556 3.91448C21.7578 4.38602 21.517 4.82198 21.1386 5.16896C20.9494 5.33801 20.6999 5.44477 20.5795 5.69389C20.1237 5.89852 19.6765 6.10315 19.2207 6.30779C18.7648 6.45904 18.309 6.60139 17.8446 6.75264C17.3888 6.84161 16.9329 6.93058 16.4771 7.01065C15.3246 7.02845 14.1808 7.1619 13.0197 7.02845C11.8501 6.90389 10.7062 6.69926 9.61393 6.2722C8.46147 5.81845 7.37781 5.23124 6.90479 3.91448Z" fill="#FCD44F"/>
      <path d="M0.0503964 15.2581C-0.0700101 14.0837 0.600826 13.3897 1.46087 12.8292C2.63054 12.064 3.93781 11.7259 5.27948 11.5213C5.81271 11.4412 6.35454 11.3968 6.88777 11.3345C7.64461 11.3434 8.39285 11.3345 9.14969 11.4234C10.7494 11.6103 12.3061 11.9306 13.6907 12.8203C14.5852 13.3897 15.2388 14.1281 15.1098 15.3293C14.8604 15.7029 14.6884 16.1211 14.353 16.4325C14.1466 16.6282 13.8628 16.7439 13.7251 17.0197C13.2693 17.2243 12.8221 17.429 12.3663 17.6336C11.9104 17.7848 11.4546 17.9272 10.9902 18.0784C10.5344 18.1674 10.0785 18.2564 9.62272 18.3365C8.47026 18.3365 7.32639 18.4966 6.16533 18.3543C4.99567 18.2119 3.8518 18.034 2.75954 17.598C1.60708 17.162 0.523422 16.5748 0.0503964 15.2581Z" fill="#FCD44F"/>
      <path d="M6.90554 6.75282C6.87974 6.40583 6.86254 6.06774 7.05175 5.75635C7.59358 6.61936 8.37622 7.15319 9.27067 7.53576C11.1972 8.37208 13.2269 8.57672 15.291 8.47885C15.6866 8.46105 16.1166 8.50554 16.4951 8.3187C16.9595 8.25642 17.4239 8.22083 17.8625 8.05179C18.3528 7.99841 18.8258 7.90054 19.2386 7.60694C19.746 7.49127 20.2019 7.25105 20.6061 6.90407C20.8383 6.88627 20.9845 6.72613 21.1393 6.57488C21.3801 6.33466 21.6123 6.08554 21.8446 5.83642C22.008 6.12113 21.9822 6.43252 21.9564 6.73502C21.7585 7.20657 21.5177 7.64252 21.1393 7.98951C20.9501 8.15855 20.7007 8.26532 20.5803 8.51444C20.1245 8.71907 19.6772 8.9237 19.2214 9.12833C18.7656 9.27958 18.3098 9.42194 17.8453 9.57319C17.3895 9.66216 16.9337 9.75113 16.4779 9.8401C15.3598 9.8401 14.2417 10.0002 13.1065 9.85789C11.9282 9.70664 10.7585 9.55539 9.64909 9.11944C8.48802 8.66569 7.39576 8.07848 6.90554 6.75282Z" fill="#FCD44E"/>
      <path d="M14.9642 17.1001C15.162 17.4382 15.119 17.803 15.1104 18.1766C14.7836 18.7905 14.4052 19.3511 13.8031 19.7158C13.7601 19.7425 13.7515 19.8137 13.7257 19.8671C13.2699 20.0717 12.8227 20.2763 12.3668 20.481C11.911 20.6322 11.4552 20.7746 10.9908 20.9258C10.5263 20.8991 10.0877 21.1483 9.61469 21.1038C9.47708 21.1305 9.33947 21.175 9.19327 21.1927C8.21281 21.2728 7.24096 21.3173 6.25191 21.2105C5.07364 21.086 3.90398 20.9169 2.79452 20.4721C1.63345 20.0005 0.506793 19.44 0.0251669 18.0966C-0.000634481 17.7585 -0.0178354 17.4204 0.171375 17.109C0.713204 17.972 1.49585 18.5058 2.3903 18.8884C4.3512 19.7425 6.40671 19.9294 8.49663 19.8315C8.86645 19.8137 9.26207 19.8582 9.61469 19.6713C10.0791 19.6091 10.5435 19.5735 10.9822 19.4044C11.4724 19.3511 11.9454 19.2532 12.3582 18.9596C12.8743 18.8884 13.2613 18.5325 13.7343 18.3457C14.2589 18.0343 14.6374 17.5894 14.9642 17.1001Z" fill="#FCD44E"/>
      <path d="M0.0507078 18.0962C0.532334 19.4396 1.65039 20.0002 2.82006 20.4717C3.92952 20.9166 5.09058 21.0856 6.27745 21.2102C7.2665 21.308 8.23835 21.2724 9.21881 21.1924C9.35641 21.1835 9.49402 21.139 9.64023 21.1034C9.64023 21.5838 9.68323 22.0732 9.61443 22.5536C6.96549 22.8739 4.38534 22.6871 1.93421 21.486C1.87401 21.4504 1.8052 21.4237 1.745 21.3881C0.437729 20.6852 -0.190105 19.6265 0.0507078 18.0962Z" fill="#FBC10A"/>
      <path d="M9.63999 19.68C9.28737 19.858 8.88315 19.8224 8.52193 19.8402C6.43202 19.9291 4.37651 19.7423 2.4156 18.8971C1.52115 18.5056 0.738507 17.9718 0.196678 17.1177C0.00746741 16.5127 0.0160679 15.8899 0.0504698 15.2671C0.523496 16.5839 1.60715 17.1711 2.76822 17.6248C3.86048 18.0519 5.01294 18.2387 6.174 18.3811C7.33507 18.5234 8.47893 18.3633 9.63139 18.3633C9.63139 18.7903 9.63139 19.2352 9.63999 19.68Z" fill="#FBC10A"/>
      <path d="M16.4949 8.32749C16.1078 8.51433 15.6864 8.46985 15.2908 8.48764C13.2267 8.58551 11.197 8.37198 9.27046 7.54455C8.37601 7.16198 7.59337 6.61926 7.05154 5.76514C6.86233 5.16014 6.87093 4.53734 6.90533 3.91455C7.37835 5.23132 8.46201 5.81852 9.62308 6.27227C10.7153 6.69933 11.8678 6.89507 13.0289 7.02852C14.1899 7.15308 15.3338 7.02852 16.4862 7.01073C16.4949 7.44668 16.4949 7.89154 16.4949 8.32749Z" fill="#FBC10A"/>
      <path d="M6.90505 6.75244C7.39528 8.0781 8.48754 8.66531 9.6658 9.12796C10.7753 9.56391 11.9449 9.71516 13.1232 9.86641C14.2585 10.0088 15.3679 9.83972 16.4946 9.84862C16.4946 10.2935 16.4946 10.7294 16.5032 11.1743C15.9441 11.3344 15.3593 11.3433 14.7831 11.3611C14.6111 11.3611 14.4047 11.2721 14.267 11.459C13.0028 10.8362 11.6955 10.3557 10.3108 10.16C9.6056 10.0621 8.90036 9.99097 8.19512 9.9109C7.8511 9.44825 7.33507 9.13685 7.05986 8.60303C6.86205 7.98913 6.87065 7.37524 6.90505 6.75244Z" fill="#FBC10A"/>
      <path d="M14.259 11.4505C14.4052 11.2636 14.6031 11.3526 14.7751 11.3526C15.3513 11.3437 15.9275 11.3348 16.4952 11.1658C16.9596 11.1035 17.4154 11.0679 17.8626 10.9078C18.3529 10.8544 18.8259 10.7565 19.2387 10.4629C19.7461 10.3472 20.202 10.107 20.6062 9.76003C20.8384 9.74224 20.9846 9.58209 21.1394 9.43084C21.3802 9.19062 21.6124 8.9415 21.8446 8.69238C22.0081 8.97709 21.9823 9.28849 21.9565 9.59099C21.7586 10.0625 21.5178 10.4985 21.1394 10.8455C20.9502 11.0145 20.7008 11.1213 20.5804 11.3704C20.1246 11.575 19.6773 11.7797 19.2215 11.9843C18.7657 12.1355 18.3099 12.2779 17.8454 12.4291C17.381 12.4469 16.9424 12.6783 16.4694 12.6071C16.2199 12.705 15.9533 12.705 15.6953 12.6961C15.2653 12.2156 14.7579 11.8419 14.259 11.4505Z" fill="#FCD44F"/>
      <path d="M16.4948 15.4184C16.4862 14.9735 16.4862 14.5287 16.4088 14.0838C16.4346 14.0571 16.4604 14.0305 16.4862 14.0038C16.9506 13.9415 17.4065 13.9059 17.8537 13.7369C18.3439 13.6835 18.8169 13.5856 19.2298 13.292C19.7372 13.1763 20.193 12.9361 20.5972 12.5891C20.8295 12.5713 20.9757 12.4112 21.1305 12.2599C21.3713 12.0197 21.6035 11.7706 21.8357 11.5215C21.9991 11.8062 21.9733 12.1176 21.9475 12.4201C21.7497 12.8916 21.5089 13.3276 21.1305 13.6746C20.9413 13.8436 20.6918 13.9504 20.5714 14.1995C20.1156 14.4041 19.6684 14.6088 19.2126 14.8134C18.7567 14.9646 18.3009 15.107 17.8365 15.2582C17.3979 15.2671 16.9678 15.5074 16.4948 15.4184Z" fill="#FCD44F"/>
      <path d="M16.4948 18.2567C16.4948 17.7851 16.4948 17.3136 16.5034 16.8509C16.9592 16.7709 17.4236 16.7531 17.8622 16.584C18.3525 16.5306 18.8255 16.4328 19.2383 16.1392C19.7457 16.0235 20.2016 15.7833 20.6058 15.4363C20.838 15.4185 20.9842 15.2584 21.139 15.1071C21.3798 14.8669 21.612 14.6178 21.8442 14.3687C22.0077 14.6534 21.9819 14.9648 21.956 15.2673C21.7066 15.9078 21.3282 16.4417 20.7606 16.8153C20.6746 16.8687 20.5972 16.931 20.58 17.0467C20.1241 17.2513 19.6769 17.4559 19.2211 17.6606C18.7653 17.8118 18.3094 17.9542 17.845 18.1054C17.3978 18.0965 16.9592 18.2923 16.4948 18.2567Z" fill="#FCD44F"/>
      <path d="M16.4948 18.2564C16.9592 18.292 17.3979 18.0963 17.8537 18.0874C17.8537 18.5412 17.8623 18.986 17.8623 19.4398C17.3979 19.6533 16.899 19.6622 16.4088 19.7512C16.4432 19.2529 16.469 18.7547 16.4948 18.2564Z" fill="#FCDF81"/>
      <path d="M9.60632 22.5451C9.67513 22.0647 9.63212 21.5843 9.63212 21.0949C10.1052 21.1483 10.5352 20.8903 11.0082 20.917C11.0082 21.3707 11.0082 21.8156 11.0168 22.2693C10.5524 22.4651 10.0879 22.5362 9.60632 22.5451Z" fill="#FCDF81"/>
      <path d="M21.8445 8.68321C21.6123 8.93233 21.3715 9.18145 21.1393 9.42167C20.9845 9.57292 20.8383 9.73306 20.6061 9.75086C20.5975 9.34159 20.5975 8.93233 20.5889 8.52306C20.7093 8.27395 20.9587 8.16718 21.1479 7.99814C21.5263 7.65115 21.7671 7.2152 21.9649 6.74365C22.0337 7.40203 21.9305 8.04262 21.8445 8.68321Z" fill="#FBC10A"/>
      <path d="M21.8445 11.5216C21.6123 11.7707 21.3715 12.0198 21.1393 12.26C20.9845 12.4113 20.8383 12.5714 20.6061 12.5892C20.5975 12.18 20.5889 11.7707 20.5889 11.3614C20.7093 11.1123 20.9587 11.0056 21.1479 10.8365C21.5263 10.4895 21.7671 10.0536 21.9649 9.58203C22.0337 10.2404 21.9305 10.881 21.8445 11.5216Z" fill="#FBC10A"/>
      <path d="M21.8445 14.3507C21.6123 14.5998 21.3715 14.8489 21.1393 15.0891C20.9845 15.2404 20.8383 15.4005 20.6061 15.4183C20.5975 15.0091 20.5889 14.5998 20.5889 14.1905C20.7093 13.9414 20.9587 13.8347 21.1479 13.6656C21.5263 13.3186 21.7671 12.8827 21.9649 12.4111C22.0337 13.0695 21.9305 13.7101 21.8445 14.3507Z" fill="#FBC10A"/>
      <path d="M20.5889 17.0289C20.5975 16.9133 20.6835 16.851 20.7695 16.7976C21.3457 16.4239 21.7155 15.8901 21.9649 15.2495C22.1284 16.5129 21.8101 17.5716 20.7007 18.2656C20.4943 17.8741 20.6663 17.4382 20.5889 17.0289Z" fill="#FBC10A"/>
      <path d="M21.8445 5.84532C21.6123 6.09444 21.3715 6.34356 21.1393 6.58378C20.9845 6.73503 20.8383 6.89517 20.6061 6.91297C20.5975 6.5037 20.5889 6.09444 20.5889 5.68517C20.7093 5.43606 20.9587 5.32929 21.1479 5.16025C21.5263 4.81326 21.7671 4.37731 21.9649 3.90576C22.0337 4.56414 21.9305 5.20473 21.8445 5.84532Z" fill="#FBC10A"/>
      <path d="M13.734 19.8667C13.7598 19.8133 13.7684 19.7422 13.8114 19.7155C14.4134 19.3507 14.7919 18.7902 15.1187 18.1763C15.1273 18.7724 15.1875 19.3685 14.9553 19.9468C14.6543 20.4094 14.3016 20.8098 13.8458 21.1123C13.6394 20.7119 13.8114 20.276 13.734 19.8667Z" fill="#FBC10A"/>
      <path d="M20.5889 17.0288C20.6749 17.4381 20.4942 17.874 20.7007 18.2744C20.262 18.5947 19.8062 18.8883 19.2644 18.9862C19.2558 18.5413 19.2386 18.0876 19.23 17.6427C19.6858 17.4381 20.133 17.2334 20.5889 17.0288Z" fill="#FCDF81"/>
      <path d="M19.2297 17.6426C19.2383 18.0874 19.2555 18.5412 19.2641 18.986C18.8255 19.2352 18.3439 19.3419 17.8622 19.4398C17.8622 18.986 17.8536 18.5412 17.8536 18.0874C18.3095 17.9362 18.7739 17.7849 19.2297 17.6426Z" fill="#FBC10A"/>
      <path d="M13.734 19.8667C13.8114 20.276 13.648 20.7119 13.8458 21.1123C13.4072 21.4326 12.9514 21.7262 12.4095 21.8241C12.4009 21.3792 12.3837 20.9254 12.3751 20.4806C12.8223 20.276 13.2782 20.0713 13.734 19.8667Z" fill="#FCDF81"/>
      <path d="M12.3749 20.4717C12.3835 20.9165 12.4007 21.3703 12.4093 21.8151C11.9706 22.0643 11.489 22.171 11.0074 22.2689C11.0074 21.8151 11.0074 21.3703 10.9988 20.9165C11.4546 20.7742 11.9104 20.6229 12.3749 20.4717Z" fill="#FBC10A"/>
      <path d="M7.05204 8.59424C7.32725 9.12806 7.84328 9.43946 8.1873 9.90211C7.75728 9.911 7.31865 10.0089 6.89723 9.83093C6.90583 9.42166 6.81983 8.98571 7.05204 8.59424Z" fill="#FCD44E"/>
      <path d="M19.2383 7.62449C18.8255 7.91809 18.3525 8.01596 17.8622 8.06934C17.8622 7.62449 17.8622 7.18853 17.8536 6.74368C18.3095 6.59243 18.7653 6.45008 19.2297 6.29883C19.2297 6.73478 19.2383 7.17964 19.2383 7.62449Z" fill="#FBC10A"/>
      <path d="M17.8536 6.74365C17.8536 7.18851 17.8536 7.62446 17.8622 8.06931C17.4236 8.22946 16.9592 8.27395 16.4948 8.33623C16.4948 7.89137 16.4948 7.45542 16.4948 7.01056C16.942 6.92159 17.3978 6.83262 17.8536 6.74365Z" fill="#FCDF81"/>
      <path d="M19.2386 7.62462C19.2386 7.17976 19.23 6.74381 19.23 6.29896C19.6858 6.09432 20.133 5.88969 20.5889 5.68506C20.5975 6.09432 20.6061 6.50359 20.6061 6.91285C20.2018 7.25984 19.7546 7.50006 19.2386 7.62462Z" fill="#FCDF81"/>
      <path d="M14.9644 17.1C14.6376 17.5893 14.2592 18.0342 13.7518 18.3456C13.7432 17.9096 13.7346 17.4648 13.726 17.0288C13.8636 16.753 14.1388 16.6374 14.3538 16.4416C14.6892 16.1302 14.8612 15.7032 15.1106 15.3384C15.1278 15.9345 15.1622 16.5306 14.9644 17.1Z" fill="#FBC10A"/>
      <path d="M12.3843 18.9682C11.9714 19.2618 11.4984 19.3597 11.0082 19.4131C11.0082 18.9682 11.0082 18.5323 11.0082 18.0874C11.464 17.9362 11.9198 17.7938 12.3843 17.6426C12.3757 18.0785 12.3757 18.5234 12.3843 18.9682Z" fill="#FBC10A"/>
      <path d="M12.3837 18.9684C12.3837 18.5235 12.3751 18.0876 12.3751 17.6427C12.8309 17.4381 13.2782 17.2334 13.734 17.0288C13.7426 17.4648 13.7512 17.9096 13.7598 18.3456C13.2868 18.5324 12.8998 18.8972 12.3837 18.9684Z" fill="#FCDF81"/>
      <path d="M10.9993 18.0874C10.9993 18.5323 10.9993 18.9682 10.9993 19.4131C10.5607 19.5732 10.0963 19.6177 9.63184 19.68C9.63184 19.2351 9.63184 18.7992 9.63184 18.3543C10.0877 18.2653 10.5435 18.1764 10.9993 18.0874Z" fill="#FCDF81"/>
      <path d="M17.8622 16.5752C17.4236 16.7442 16.9592 16.762 16.5034 16.8421C16.5034 16.3705 16.5034 15.8901 16.4948 15.4186C16.9678 15.4986 17.3978 15.2584 17.8536 15.2495C17.8536 15.6944 17.8622 16.1392 17.8622 16.5752Z" fill="#FCDF81"/>
      <path d="M16.4951 14.0035C16.4693 14.0302 16.4435 14.0569 16.4177 14.0836C16.2457 13.5764 15.9963 13.1138 15.7039 12.6778C15.9705 12.6867 16.2285 12.6867 16.4779 12.5889C16.4865 13.0604 16.4865 13.532 16.4951 14.0035Z" fill="#FBC10A"/>
      <path d="M19.2378 10.4536C18.8249 10.7472 18.3519 10.8451 17.8617 10.8984C17.8617 10.4536 17.8617 10.0176 17.8617 9.57278C18.3175 9.42153 18.7733 9.27918 19.2378 9.12793C19.2292 9.57278 19.2378 10.0176 19.2378 10.4536Z" fill="#FBC10A"/>
      <path d="M17.8534 9.58203C17.8534 10.0269 17.8534 10.4628 17.8534 10.9077C17.4147 11.0678 16.9503 11.1123 16.4859 11.1657C16.4859 10.7209 16.4859 10.2849 16.4773 9.84005C16.9417 9.75108 17.3975 9.6621 17.8534 9.58203Z" fill="#FCDF81"/>
      <path d="M19.2386 10.4537C19.2386 10.0089 19.23 9.57291 19.23 9.12806C19.6858 8.92342 20.133 8.71879 20.5889 8.51416C20.5975 8.92342 20.5975 9.33269 20.6061 9.74195C20.2018 10.0978 19.7546 10.3381 19.2386 10.4537Z" fill="#FCDF81"/>
      <path d="M19.2383 13.292C18.8255 13.5856 18.3525 13.6834 17.8622 13.7368C17.8622 13.292 17.8622 12.856 17.8536 12.4112C18.3095 12.2599 18.7653 12.1176 19.2297 11.9663C19.2297 12.4112 19.2383 12.856 19.2383 13.292Z" fill="#FBC10A"/>
      <path d="M17.8542 12.4111C17.8542 12.856 17.8542 13.2919 17.8628 13.7368C17.4241 13.8969 16.9597 13.9414 16.4953 14.0037C16.4953 13.5322 16.4867 13.0606 16.4867 12.5891C16.9597 12.6603 17.3983 12.4289 17.8542 12.4111Z" fill="#FCDF81"/>
      <path d="M19.2386 13.2921C19.2386 12.8472 19.23 12.4113 19.23 11.9664C19.6858 11.7618 20.133 11.5572 20.5889 11.3525C20.5975 11.7618 20.5975 12.1711 20.6061 12.5803C20.2018 12.9362 19.7546 13.1764 19.2386 13.2921Z" fill="#FCDF81"/>
      <path d="M17.8622 16.5752C17.8622 16.1304 17.8622 15.6944 17.8536 15.2495C18.3095 15.0983 18.7653 14.9559 19.2297 14.8047C19.2297 15.2495 19.2383 15.6855 19.2383 16.1304C18.8255 16.424 18.3525 16.5218 17.8622 16.5752Z" fill="#FBC10A"/>
      <path d="M19.2386 16.13C19.2386 15.6851 19.23 15.2492 19.23 14.8043C19.6858 14.5997 20.133 14.3951 20.5889 14.1904C20.5975 14.5997 20.5975 15.009 20.6061 15.4182C20.2018 15.7741 19.7546 16.0054 19.2386 16.13Z" fill="#FCDF81"/>
    </g>
    <defs>
      <clipPath id="clip0_246_33456">
        <rect width="22" height="22.6875" fill="white"/>
      </clipPath>
    </defs>
  </svg>

</template>
<script>
export default {
  name: 'CoinsIcon',
}
</script>
<style lang="scss" scoped>
</style>