
export default {
    name: 'CheckBox',
    props: {
        checked: {
            type: Boolean,
            default: false
        },
        value: {
            type: String,
            default: null
        },
    },
    data() {
        return {
            isChecked: false
        }
    },
    methods: {
        handleInput(e) {
            this.isChecked = !this.isChecked;
            this.$emit('update:modelValue', this.isChecked);
            this.$emit('onChecked', {
                value: this.$props.value,
                checked: this.isChecked
            });
        },
    },
    mounted() {
        this.isChecked = this.$props.checked;
    }
}