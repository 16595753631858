import Button from "@/components/Common/Buttons/Button/Button";
import ModalCloseBtn from "../ModalCloseBtn";
import InputField from '@/components/Common/Inputs/Input/Input';
import CheckBox from '@/components/Common/Inputs/CheckBox/CheckBox';
import RadioButton from '@/components/Common/Inputs/RadioButton/RadioButton';
import CoinsIcon from '@/components/Common/Icons/CoinsIcon/';
import Modal from './../Modal';

export default {
    name: 'CreditBuyModal',
    components: {
        Button,
        ModalCloseBtn,
        InputField,
        Modal,
        CheckBox,
        RadioButton,
        CoinsIcon
    },
    data() {
        return {
            selected: null,
        }
    },
    methods: {
        closeModal() {
            this.$store.dispatch("utils/closeModal", this.$props.name);
        },
        select(id) {
            this.selected = id;
        }
    }
}