import {createRouter, createWebHistory} from 'vue-router'
import store from './../store';

const routes = [
    {
        path: '/coming-soon',
        name: 'ComingSoonPage',
        meta: {transition: 'slide-left'},
        component: () => import('../views/ComingSoonPage/ComingSoonPage')
    },
    {
        path: '/select-cleaning-service',
        name: 'SelectCleaningServicePage',
        meta: {transition: 'slide-left'},
        component: () => import('../views/SelectCleaningServicePage/SelectCleaningServicePage')
    },
    {
        path: '/moving',
        name: 'MovingPage',
        meta: {transition: 'slide-left'},
        component: () => import('../views/MovingCleaningPage/MovingCleaningPage')
    },
    {
        path: '/cleaning',
        name: 'CleaningPage',
        meta: {transition: 'slide-left'},
        component: () => import('../views/CleaningPage/CleaningPage')
    },
    {
        path: '/interior',
        name: 'InteriorPage',
        meta: {transition: 'slide-left'},
        component: () => import('../views/MovingCleaningPage/MovingCleaningPage')
    },
    {
        path: '/profile',
        name: 'Profile',
        component: () => import('../views/ProfileContainer/ProfileContainer'),
        children: [
            {
                path: 'property',
                name: 'AddProperty',
                component: () => import('../views/AdminAddProperty/AdminAddProperty')
            },
            {
                path: 'property/:id',
                name: 'EditProperty',
                component: () => import('../views/AdminAddProperty/AdminAddProperty')
            },
            {
                path: 'properties',
                name: 'AdminPropertyList',
                component: () => import('../views/AdminPropertyList/AdminPropertyList')
            },
            {
                path: 'vendor/dashboard',
                name: 'VendorDashboard',
                component: () => import('../views/AdminDashboard/AdminDashboardVendor/AdminDashboardVendor')
            },
            {
                path: 'dashboard',
                name: 'AgencyDashboard',
                component: () => import('../views/AdminDashboard/AdminDashboardProperty/AdminDashboardProperty')
            },
            {
                path: 'settings',
                name: 'AdminSettings',
                component: () => import('../views/AdminSettings/AdminSettings')
            },
            {
                path: 'product',
                name: 'AdminAddProduct',
                component: () => import('../views/AdminAddProduct/AdminAddProduct')
            },
            {
                path: 'product/:id',
                name: 'UserEditProduct',
                component: () => import('../views/AdminAddProduct/AdminAddProduct')
            },
            {
                path: 'products',
                name: 'AdminListProducts',
                component: () => import('../views/AdminVendorProducts/AdminVendorProducts')
            },
            {
                path: 'order',
                name: 'AdminVendorOrders',
                component: () => import('../views/AdminVendorOrders/AdminVendorOrders')
            },
            {
                path: 'order/:id',
                name: 'AdminVendorSingleOrder',
                component: () => import('../views/AdminVendorSingleOrder/AdminVendorSingleOrder')
            },
            {
                path: 'viewing-requests',
                name: 'ViewingRequests',
                component: () => import('../views/AdminViewingRequests/AdminViewingRequests')
            },
            {
                path: 'roommate-offers',
                name: 'AdminRoommates',
                component: () => import('../views/AdminRoommates/AdminRoommates')
            },
            {
                path: 'roommate-offers/:id',
                name: 'EditRoommateOffer',
                component: () => import('../views/AddRoommateOffer/AddRoommateOffer')
            },
        ]
    },
    {
        path: '/product/category',
        name: 'MarketplaceProductCategories',
        component: () => import('../views/MarketplaceProductCategories/MarketplaceProductCategories')
    },
    {
        path: '/blog/category/:id?',
        name: 'BlogCategory',
        component: () => import('../views/BlogCategoryPage/BlogCategoryPage')
    },
    {
        path: '/blog/:id',
        name: 'BlogSinglePost',
        component: () => import('../views/BlogSinglePostPage/BlogSinglePostPage')
    },
    {
        path: '/vendor/:id',
        name: 'SingleVendor',
        component: () => import('../views/SingleVendorPage/SingleVendorPage')
    },
    {
        path: '/contacts',
        name: 'Contacts',
        component: () => import('../views/ContactPage/ContactPage')
    },
    {
        path: '/favorites',
        name: 'FavoriteProducts',
        component: () => import('../views/FavoritesPage/Favorites')
    },
    {
        path: '/cart',
        name: 'Cart',
        component: () => import('../views/Cart/Cart')
    },
    {
        path: '/product/:id',
        name: 'SingleProductPage',
        component: () => import('../views/SingleProductPage/SingleProductPage')
    },
    {
        path: '/product/category/:id',
        name: 'MarketplaceCategoryPage',
        component: () => import('../views/MarketplaceCategoryPage/MarketpaceCategoryPage')
    },
    {
        path: '/add-roommate-offer',
        name: 'AddRoommate',
        component: () => import('../views/AddRoommateOffer/AddRoommateOffer')
    },
    {
        path: '/agency/:id',
        name: 'Agency',
        component: () => import('../views/SingleAgencyPage/SingleAgencyPage')
    },
    {
        path: '/user/:id',
        name: 'User',
        component: () => import('../views/SingleAgencyPage/SingleAgencyPage')
    },
    {
        path: '/single-roommate/:id',
        name: 'SingleRoommateOffer',
        component: () => import('../views/SingleRoommateOffer/SingleRoommateOffer.vue')
    },
    {
        path: '/',
        name: 'Index',
        component: () => import('../views/Index/Index.vue')
    },
    {
        path: '/search',
        name: 'PropertySearchPage',
        component: () => import('../views/SearchResult/SearchResult.vue')
    },
    {
        path: '/search-roommate',
        name: 'SearchRoommateResult',
        component: () => import('../views/SearchRoommateResult/SearchRoommateResult.vue')
    },
    {
        path: '/property/:id',
        name: 'SinglePropertyPage',
        component: () => import('../views/SinglePropertyPage/SingplePropertyPage.vue')
    },
    {
        path: '/user/confirmation',
        name: 'UserMailConfirmation',
        component: () => import('../views/UserMailConfirmation.vue')
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFoundPage',
        component: () => import('../views/NotFound.vue')
    },
]

const router = createRouter({
    history: createWebHistory(),
    scrollBehavior() {
        document.getElementById('app').scrollIntoView();
    },
    routes: routes
})

router.beforeEach((to, from, next) => {
    store.dispatch("utils/closeMarketplaceRoomsModal");
    next();
})


export default router
