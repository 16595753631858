
export default {
    name: 'TimeInput',
    data() {
        return {
            timeValue: '',
        };
    },
    props: {
        content: {
            type: String,
        },
        error: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onDateChange(e) {
            this.$emit('update:modelValue', e.target.value);
        },
    },
}