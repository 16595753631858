import Button from "@/components/Common/Buttons/Button/Button";
import LocationIcon from "@/components/Common/Icons/LocationIcon";

export default {
    name: 'Footer',
    components: {
        Button,
        LocationIcon
    },
    computed: {
        cities() {
            return this.$store.state.location.cities
        },
    },
}