
<template>
  <svg width="23.5" height="20" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_40_5104)">
      <path d="M6.4949 13C2.90414 13 0 10.0857 0 6.4949C0 2.90414 2.91431 0 6.50507 0C10.1009 0 13.0101 2.9194 13 6.52033C12.9949 10.1009 10.0857 13.0051 6.4949 13ZM4.95891 7.13575C3.48904 6.07276 3.46869 4.196 4.41979 3.09233C5.4014 1.95813 7.10523 1.80555 8.24451 2.76682C8.85992 3.2856 9.20068 3.95696 9.22612 4.76055C9.25663 5.75234 8.83957 6.53559 8.04106 7.13066C9.48551 7.71556 10.4112 8.74803 10.8333 10.2332C12.5626 8.33606 12.9288 4.88262 10.5943 2.482C8.31062 0.137324 4.48591 0.198356 2.27347 2.62441C0.0406885 5.07589 0.523864 8.44795 2.16666 10.2332C2.58881 8.74294 3.51955 7.72064 4.95891 7.13575ZM6.69834 12.2422C6.8967 12.2167 7.29341 12.1862 7.68504 12.1099C8.58527 11.9319 9.39396 11.5352 10.106 10.9605C10.1365 10.935 10.1721 10.8791 10.167 10.8435C10.0704 10.1263 9.80084 9.48042 9.31766 8.9413C8.29537 7.80202 7.02385 7.40022 5.53872 7.76642C4.13497 8.11227 3.04655 9.35327 2.83802 10.7926C2.82785 10.8638 2.82785 10.9096 2.89397 10.9656C3.95187 11.7997 5.15218 12.2218 6.69834 12.2422ZM6.50507 6.84075C7.59858 6.83567 8.48864 5.94052 8.47846 4.86228C8.46829 3.77386 7.57315 2.88888 6.48981 2.89397C5.41157 2.89906 4.52659 3.7942 4.52659 4.86736C4.52659 5.95578 5.42174 6.84584 6.50507 6.84075Z" fill="#434D56"/>
    </g>
    <defs>
      <clipPath id="clip0_40_5104">
        <rect width="13" height="13" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ProfileIcon',
}
</script>
<style lang="scss" scoped>
@import "~@/assets/css/variables.scss";
</style>