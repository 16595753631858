<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>

  <Modal :name="'passChange'"
         :is-open="$store.state.utils.modals.passChange"
         :title="'Смяна на парола'"
  >
    <div style="width: 420px; max-width: 100%">
      <div class="mb-3">
        <InputField label="Въведи силна парола" inputtype="password" v-model="form.password" :content="form.password"
                    :error="fieldErrors.includes('password')"/>
      </div>
      <div class="mb-4">
        <InputField label="Повтори паролата" inputtype="password" v-model="form.password2" :content="form.password2"
                    :error="fieldErrors.includes('password2')"
        />
      </div>
      <div class="mb-2 mt-4">
        <Button @click="changePass()" :theme="'solid-green'" :size="'medium'" :text="'Смяна на парола'"/>
      </div>
    </div>
  </Modal>

</template>