import axios from 'axios';

export default {
    namespaced: true,
    state: {
        post: {},
        featuredPosts: [],
        categoryPosts: [],
        posts: [],
        newestPosts: [],
        categories: [],
        products: [],
    },
    mutations: {
        SET_FEATURED_POSTS(state, posts) {
            state.featuredPosts = posts;
        },
        SET_POSTS(state, posts) {
            state.posts = posts;
        },
        SET_NEWEST_POSTS(state, posts) {
            state.newestPosts = posts;
        },
        SET_CATEGORY_POSTS(state, posts) {
            state.categoryPosts = posts;
        },
        SET_CATEGORIES(state, categories) {
            state.categories = categories;
        },
        SET_VIEW_POST(state, post) {
            state.post = post;
        },
        SET_VIEW_POST_LINKED_PRODUCTS(state, products) {
            state.products = products;
        },
    },
    actions: {
        async getCategories({commit}) {
            const response = await axios.get('/api/news/categories');
            commit('SET_CATEGORIES', response.data.data);
            return response.data;
        },
        async getCategoryPosts({commit}, payload) {
            let url = '/api/news?type=blog';
            if (payload.hasOwnProperty('category')) {
                url += '&category_id=' + payload.category;
            }
            if (payload.hasOwnProperty('offset')) {
                url += '&ooffset=' + payload.offset;
            }
            if (payload.hasOwnProperty('limit')) {
                url += '&limit=' + payload.limit;
            }
            const response = await axios.get(url);
            commit('SET_CATEGORY_POSTS', response.data.news);
            return response.data.news;
        },
        async getNewestPosts({commit}) {
            const response = await axios.get('/api/news?limit=10');
            commit('SET_NEWEST_POSTS', response.data.news);
            return response.data.news;
        },
        async getFeaturedPosts({commit}, payload) {
            let url = '/api/news?limit=4&highlighted=1';
            if (payload.hasOwnProperty('exclude')) {
                url += '&exclude=' + payload.exclude.join(',');
            }
            const response = await axios.get(url);
            commit('SET_FEATURED_POSTS', response.data.news);
            return response.data.news;
        },
        async getPost({commit}, id) {
            const response = await axios.get('/api/news/'+id);
            commit('SET_VIEW_POST', response.data);
            return response.data;
        },
        async getLinkedProducts({commit}, id) {
            const response = await axios.get('/api/page/blog-post/1-products.json?post_id='+id);
            commit('SET_VIEW_POST_LINKED_PRODUCTS', response.data.data);
            return response.data;
        },
        async postComment({commit}, id, comment) {
            const response =  axios.post('/api/page/blog-post/'+id+'/postComment', comment);
            return response.data;
        },
    }
};