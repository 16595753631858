<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>

  <Modal :name="'login'" :is-open="$store.state.utils.modals.login" :title="$t('login-and-registration')">
    <div style="width: 420px; max-width: 100%">
      <div class="mb-2">
        <div class="alert alert-danger" role="alert" v-if="$store.state.user.authenticationFailed">
          {{ $t('wrong-username-or-password') }}
        </div>
      </div>
      <div class="mb-2">
        <InputField v-model="email" :content="email" :label="'E-mail'" name="email" :inputtype="'text'" :size="'small'"
                    :error="formErrors.includes('email')"/>
      </div>
      <div class="mb-3">
        <InputField v-model="password" :content="password" :label="$t('password')" name="password"
                    :inputtype="'password'"
                    :size="'small'"
                    :error="formErrors.includes('password')"/>
      </div>
      <div class="mb-3">
        <Button @click="login()" :theme="'solid-green'" :size="'medium'" :text="$t('login')"/>
      </div>
      <div class="mb-3">
        <Button @click="openForgottenPasswordModal()" :size="'medium'" :text="$t('forgotten-password')"/>
      </div>
      <div class="mt- mb-2 signup">
        <Button @click="$store.dispatch('utils/openModal', 'signUp');"
                :theme="'solid-grey'"
                :size="'medium'"
                :text="$t('create-account')"/>
      </div>
      <div class="mt-2 sub-title-sm">
        {{ $t('or-use-your-social-media-accounts') }}
      </div>
      <div class="mt-2">
        <Button :theme="'fb'" :size="'medium'" :text="'Facebook'"/>
      </div>
      <div class="mt-2">
        <GoogleLogin :callback="googleLoginCallback" class="w-100">
          <Button :theme="'google'" :size="'medium'" :text="'Google'"/>
        </GoogleLogin>
      </div>
    </div>

  </Modal>

</template>
