<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{
    'input-field input-group has-validation': true,
    'right-inner-addon': true,
    'input-size-large': size === 'large',
    'input-size-small': size === 'small',
    'placeholder-left': theme === 'placeholder-left',
    'border-rectangle': theme === 'border-rectangle',
  }">
    <input @input="handleInput"
           v-if="inputtype === 'phone'"
           :value="content"
           v-bind:placeholder="label"
           type="tel"
           pattern="[0]{1}[8]{1}[7-9]{1}[2-9]{1}[0-9]{6}"
           v-bind:class="{'form-control': true, 'is-invalid': error}"
           v-bind:disabled="disabled"
    />
    <input @input="handleInput"
           v-else
           :value="content"
           v-bind:type="inputtype"
           v-bind:placeholder="label"
           v-bind:class="{'form-control': true, 'is-invalid': error}"
           v-bind:disabled="disabled"
           v-bind:maxlength="maxLength"
           v-bind:name="name"
    />
  </div>
</template>

