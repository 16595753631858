import axios from 'axios';
import store from './../store';

export default class Axios {
	static async get(url, config = {}) {
		try {
			return await axios.get(url, config);
		} catch (error) {
			this.handleAccessDenied(error);
			return Promise.reject(error);
		}
	}

	static async post(url, data = {}, config = {}) {
		try {
			return await axios.post(url, data, config);
		} catch (error) {
			this.handleAccessDenied(error);
			return Promise.reject(error);
		}
	}

	static async delete(url, config = {}) {
		try {
			return await axios.delete(url, config);
		} catch (error) {
			this.handleAccessDenied(error);
			return Promise.reject(error);
		}
	}

	static handleAccessDenied(error) {
		if (error.response && (error.response.status === 401 || error.response.status === 403)) {
			store.dispatch("utils/openModal", 'login');
		}
	}
}
