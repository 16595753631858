import { t, createI18n } from "vue-i18n";
import axios from "axios";
import { get } from 'lodash';

// List of all locales.
export const allLocales = ["bg", "en",  "ro"];
const loadedLanguages = ["bg"]; // our default language that is preloaded

// Create Vue I18n instance.
export const i18n = createI18n({
  // legacy: false,
  globalInjection: true,
  // allowComposition: true,
  locale: "bg",
  fallbackLocale: "bg",
  // useScope: "global",
  messageResolver: (obj, path) => get(obj, path, '')?.replaceAll(/@/g, "{'@'}") || null,
});

// Set new locale.
export const setLang =  async (lang) => {
  i18n.locale = lang;  
  i18n.global.locale = lang;  
  axios.defaults.headers.common["Accept-Language"] = lang;
  document.querySelector("html").setAttribute("lang", lang);
  window.localStorage.setItem('defaultLang', lang);
  loadLocale(lang);
}

// Fetch locale.
export function loadLocale(lang) {
  // // If the same language
  // if (i18n.locale === lang) {
  //   return Promise.resolve(setLang(lang));
  // }

  // // If the language was already loaded
  // if (loadedLanguages.includes(lang)) {
  //   return Promise.resolve(setLang(lang));
  // }
  // If the language hasn't been loaded yet


  axios.get('/api/localization/translation/' + lang).then((response)=>{
    i18n.global.setLocaleMessage(lang, response.data.translations)
    loadedLanguages.push(lang);
  });
  // commit('SET_PROPERTY', response.data);

}