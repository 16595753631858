import axios from 'axios'

export default {
    namespaced: true,
    state: {
        citiesOptions: {},
        cities: [],
    },
    mutations: {
        SET_CITIES(state, cities) {
            state.cities = cities;
            cities.forEach((item) => {
                state.citiesOptions[item.name] = item.id;
            });
        },
    },
    actions: {
        async getCities({commit, state}) {
            if (state.cities.length > 0) {
                return state.cities;
            }
            const response = await axios.get('/api/location/city');
            commit('SET_CITIES', response.data);
            return response.data;
        },
        async getCitiesMapped({dispatch}) {
            const result = await dispatch('getCities');
            if (result.length === 0) {
                return {};
            }

            const cities = {};
            result.forEach((item) => {
                cities[item.name] = item.id;
            });

            return cities;
        },
        async getDistrictsMapped({dispatch}, cityId) {

            const propertyLocationDistricts = {};
            const cities = await dispatch('getCities');
            const citiesMapped = await dispatch('getCitiesMapped');

            //Loop trough mapped cities to fing the one that matches the selected one
            Object.keys(citiesMapped).forEach((cityName) => {
                const currentCityId = parseInt(citiesMapped[cityName]);
                if (currentCityId === parseInt(cityId)) {
                    //Loop through full cities list to find the districts
                    Object.keys(cities).forEach((key) => {
                        if (cities[key].id === currentCityId) {
                            const districts = cities[key].district;
                            districts.forEach((item) => {
                                propertyLocationDistricts[item.name] = item.id;
                            });
                        }
                    });
                }
            });

            return propertyLocationDistricts;
        },
        async getCityIdByDistrictId({dispatch}, districtId) {
            const cities = await dispatch('getCities');
            let cityId = null;
            cities.forEach((city) => {
                city.district.forEach((district) => {
                    if (district.id === districtId) {
                        cityId = city.id;
                    }
                });
            });

            return cityId;
        }
    }
}