
export default {
	name: 'TextArea',
	props: {
		input: {
			type: String,
		},
		content: {
			type: String,
		},
		size: {
			type: String,
			default: 'medium',
		},
		label: {
			type: String,
			default: '',
		},
		error: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		handleInput(e) {
			this.$emit('update:modelValue', e.target.value);
		},
	},
};