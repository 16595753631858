import axios from 'axios';

export default {
  namespaced: true,
  state: typeof localStorage.getItem('cart') !== "undefined" && localStorage.getItem('cart') !== null ? JSON.parse(localStorage.getItem('cart')) : {
    products: {},
    totalOrderAmount: 0,
    totalDeliveryAmount: 0,
    totalProductCount: 0,
  },
  mutations: {
    SET_CART(state, payload) {
      state = payload;
    },
    RESET_CART(state) {
      state = {};
    },
    CHANGE_PRODUCT_QUANTITY(state, payload) {
      const quantity = payload.quantity;
      const productId = payload.productId;
      Object.keys(state.products).forEach((vendorId) => {
        const products = state.products[vendorId].products;
        Object.keys(products).forEach((id) => {
          if (state.products[vendorId].products[id].id === productId) {
            state.products[vendorId].products[id].quantity = quantity;
          }
        });
      });
      state = calculateProductsCountAndPrice(state);
    },
    REMOVE_PRODUCT(state, id) {
      Object.keys(state.products).forEach((vendorId) => {
        const products = state.products[vendorId].products;
        Object.keys(products).forEach((productId) => {
          if (products[productId].id === id) {
            delete state.products[vendorId].products[productId];
          }
        });
      });
      state = calculateProductsCountAndPrice(state);
    },
    ADD_PRODUCT(state, payload) {
      console.log('===> state==', localStorage.getItem('cart'))
      const vendorId = payload.vendor.id;
      if (!state.products.hasOwnProperty(vendorId)) {
        state.products[vendorId] = {
          vendor: payload.vendor,
          products: {
            [payload.id]: payload,
          },
          totalAmount: 0,
          totalTaxes: 0,
        };
      }

      state.products[vendorId]['products'][payload.id] = payload;
      state = calculateProductsCountAndPrice(state);
    },
  },
  actions: {
    async addProduct({ commit }, product) {
      console.log('===> Add Product <=====', product)
      if (!product.hasOwnProperty('id')) {
        throw new Error('Product ID is required');
      }
      if (!product.hasOwnProperty('quantity')) {
        throw new Error('Product quantity is required');
      }
      if (!product.hasOwnProperty('vendor') || !product.vendor.hasOwnProperty('id')) {
        throw new Error('Product vendor is required');
      }
      if (product.quantity > 100) {
        throw new Error('Product quantity cannot be greater than 100');
      }
      if (product.quantity <= 0) {
        throw new Error('Product quantity cannot be less than 1');
      }

      const data = {
        id: product.id,
        title: product.title,
        price: product.price,
        promoPrice: product.promoPrice,
        image: product.image,
        vendor: product.vendor,
        quantity: product.quantity,
        categories: product.categories,
      }

      commit('ADD_PRODUCT', data);
    },
    async removeProductFromCart({ commit }, id) {
      commit('REMOVE_PRODUCT', id);
    },
    async changeProductFromCart({ commit }, payload) {
      commit('CHANGE_PRODUCT_QUANTITY', payload);
    },
    async checkout({ commit }, order) {
      try {
        const response = await axios.post(`/api/order`, order);
        commit('RESET_CART');
        return response.data;
      } catch (e) {
        throw e;
      }
    },
  }
};

function calculateProductsCountAndPrice(state) {
  state.totalOrderAmount = 0;
  state.totalProductCount = 0;
  Object.keys(state.products).forEach((vendorId) => {
    state.products[vendorId]['totalAmount'] = 0;
    state.products[vendorId]['totalTaxes'] = 0;
    const products = state.products[vendorId].products;
    Object.keys(products).forEach((productId) => {
      const product = products[productId];
      state.products[vendorId]['totalAmount'] += (parseFloat(product.price) * parseInt(product.quantity));
    });
    state.products[vendorId]['totalTaxes'] = state.products[vendorId]['totalAmount'] * 0.2;
    state.totalProductCount += Object.keys(state.products[vendorId].products).length;
    state.totalOrderAmount += state.products[vendorId]['totalAmount'];
  });

  localStorage.setItem('cart', JSON.stringify(state));

  return state;
}