<template>
  <div>
    <!-- Your SVG -->
    <svg version="1.1" id="Layer_2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
         x="0px" y="0px"
         viewBox="0 0 2857.1 1628.5" style="enable-background:new 0 0 2857.1 1628.5;" xml:space="preserve">
      <g>
          <defs>
          <path id="SVGID_1_" d="M162.6,236.2l62.1,320.2L0,632.1l36.7,167.7l415.1,308.3l225.9-105l944.2,625.4l1116.3-812.3l33.8-172
          l-9.2-3.2l86.5-60.8l-12.3-6.1l20.2-102.6l-49.7,22l-81.7-25.5c0,0-0.9-20.3-4-22.7c-3.1-2.4-27.1,12-27.1,12l-282.9-77.7
          l-33.1-4.4l-8.8-10c0,0,3.3-18.4,2.8-29.1c-9.3,4.7-64.5,8-108.8,0c0.6,10.5,0.8,13.9,0.8,13.9L2127,420.4l-100.7-34.2l-37.9,16.9
          l0.6-16.1l-12.4,6l19.8-254.5L1534.5,0l-408,129.3L871.5,37.8L162.6,236.2z"/>
          </defs>
        <clipPath id="SVGID_2_">
          <use xlink:href="#SVGID_1_" style="overflow:visible;"/>
          </clipPath>
        <polygon v-bind:style="{fill: isHovered.terrace ? 'rgba(255,141,47,0.64)' : 'rgba(255,141,47,0)', transition: 'fill 0.3s'}"
                 @mouseover="handleMouseOver('terrace')"
                 @mouseout="handleMouseOut('terrace')"
                 @click="redirectTo('MarketplaceProductCategories', {}, {room: 'terrace'})"
                 id="terrace" class="st0" points="0,632.1 224.8,556.4 162.6,236.2 327,321.5 332,352.6 232.7,301.2 279.5,566
      639.2,787.4 618.4,797 656.2,820.1 677.6,1003.1 451.8,1108.1 36.7,799.8 	"/>
        <polyline
            v-bind:style="{fill: isHovered.bedroom ? 'rgba(255,141,47,0.64)' : 'rgba(255,141,47,0)', transition: 'fill 0.3s'}"
            @mouseover="handleMouseOver('bedroom')"
            @mouseout="handleMouseOut('bedroom')"
            @click="redirectTo('MarketplaceProductCategories', {}, {room: 'bedroom'})"
            id="Bedroom" class="st0" points="872.3,37.8 162.6,236.2 327,321.5 332,352.6 232.7,301.2 279.5,566 639.2,787.4
      664.9,775.8 691.3,793 1305,535 1219.8,498.1 1219.8,484.1 1214.2,481.1 1203.9,175 1136.9,151.3 1164.8,143 871.5,37.8 	"/>
        <polygon v-bind:style="{fill: isHovered.corridor ? 'rgba(255,141,47,0.64)' : 'rgba(255,141,47,0)', transition: 'fill 0.3s'}"
                 @mouseover="handleMouseOver('corridor')"
                 @mouseout="handleMouseOut('corridor')"
                 @click="redirectTo('MarketplaceProductCategories', {}, {room: 'hallway'})"
                 id="Corridor" class="st0" points="1534.5,0 1126.5,129.3 1164.8,143 1136.9,151.3 1203.9,175 1214.2,481.1 1219.8,484.1
      1219.8,498.1 1305,535 1179.5,587.7 1345.8,665.7 1332.5,670.6 1436.3,721 1447.3,715.1 1487.3,733.3 1487.4,760.6 1561.5,796.3
      1741.5,701.4 1743.8,648.6 1752.5,643.6 1754.8,597.7 1776.1,593.1 1795.1,598.8 1800,473.5 1976.6,392.9 1996.4,138.5 	"/>
        <polygon v-bind:style="{fill: isHovered.kitchen ? 'rgba(255,141,47,0.64)' : 'rgba(255,141,47,0)', transition: 'fill 0.3s'}"
                 @mouseover="handleMouseOver('kitchen')"
                 @mouseout="handleMouseOut('kitchen')"
                 @click="redirectTo('MarketplaceProductCategories', {}, {room: 'kitchen'})"
                 id="Kitchen" class="st0" points="1561.5,796.3 1578.2,803.8 1578.2,808.2 1567.7,814.8 1565.4,946.7 2021.8,1180.6
      2030.5,1085.7 2176,988.5 2226.6,1010.5 2202,1205.7 1621.8,1628.5 947.7,1182.4 940.8,997.3 1487.3,733.3 1487.4,760.6 	"/>
        <polygon v-bind:style="{fill: isHovered.bathroom ? 'rgba(255,141,47,0.64)' : 'rgba(255,141,47,0)', transition: 'fill 0.3s'}"
                 @mouseover="handleMouseOver('bathroom')"
                 @mouseout="handleMouseOut('bathroom')"
                 @click="redirectTo('MarketplaceProductCategories', {}, {room: 'bathroom'})"
                 id="Bath" class="st0" points="677.6,1003.1 656.2,820.1 618.4,797 664.9,775.8 691.3,793 1179.5,587.7 1345.8,665.7
      1332.5,670.6 1436.3,721 1447.3,715.1 1487.3,733.3 940.8,997.3 947.7,1182.4 	"/>
        <path v-bind:style="{fill: isHovered.livingroom ? 'rgba(255,141,47,0.64)' : 'rgba(255,141,47,0)', transition: 'fill 0.3s'}"
              @mouseover="handleMouseOver('livingroom')"
              @mouseout="handleMouseOut('livingroom')"
              @click="redirectTo('MarketplaceProductCategories', {}, {room: 'living-room'})"
              id="living" class="st0" d="M1988.5,403.1l37.9-16.9l100.7,34.2v3.4l-15.4,7.6L2097.6,579l336.1,129.7c0,0,9.7-4.6,17.9-4.6
      c0.2-12.3,0.2-12.3,1-31.3s18.3-25.2,18.3-25.2s7.6-5.2,22.7-0.7c15,4.4,46.4,17,46.4,17l9.9-72.5l14.2-8.8l10.1-7.1l197.9,68.7
      l-33.8,172L2202,1205.7l24.6-195.2l-50.6-22l-145.5,97.3l-8.7,94.9l-456.5-234l2.3-131.8l10.5-6.6v-4.4l-16.7-7.5l180-95l2.3-52.8
      l8.7-4.9l2.3-46l21.3-4.6l19,5.7l4.9-125.3l189-86.5L1988.5,403.1z"/>
        <path v-bind:style="{fill: isHovered.garden ? 'rgba(255,141,47,0.64)' : 'rgba(255,141,47,0)', transition: 'fill 0.3s'}"
              @mouseover="handleMouseOver('garden')"
              @mouseout="handleMouseOut('garden')"
              @click="redirectTo('MarketplaceProductCategories', {}, {room: 'garden'})"
              id="Garden" class="st0" d="M2857.1,471.6L2837,574.1l12.3,6.1l-86.5,60.8L2574,575.5l-24.3,15.9l-9.9,72.5l-46.4-17
      c0,0-13.7-4.8-22.7,0.7c-9,5.6-17.7,10.4-18.3,25.2s-1,31.3-1,31.3s-9.6,0.2-17.9,4.6c-10.8-4.2-336.1-129.7-336.1-129.7
      l14.1-147.7l15.4-7.6v-3.4l137.6-70.3l-0.8-13.9c0,0,61,11.2,108.8,0c0.4,11.2-2.8,29.1-2.8,29.1l8.8,10l33.1,4.4l282.9,77.7
      c0,0,23.9-14.7,27.1-12c3.2,2.8,4,22.7,4,22.7l81.7,25.5L2857.1,471.6z"/>
      </g>
</svg>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isHovered: {
        bedroom: false,
        terrace: false,
        corridor: false,
        livingroom: false,
        garden: false,
      },
    };
  },
  methods: {
    handleMouseOver(room) {
      this.isHovered[room] = true;
    },
    handleMouseOut(room) {
      this.isHovered[room] = false;
    },
    redirectTo(route, params = {}, query = {}) {
        this.$store.dispatch("utils/toggleMarketplaceRoomsModal");
        this.$router.push({'name': route, params: params, query: query})
      }

  },
};
</script>

<style scoped>
.st0 {
  clip-path: url(#SVGID_2_);
  fill: #FF7A00;
}
</style>
