
<template>
  <div v-bind:class="{

    'color-orange-main': color === 'orange-main',
    'color-orange-light': color === 'orange-light',
    'color-dark': color === 'dark',

  }">
    <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.38531 2.19533C7.68475 1.87679 8.01913 1.61991 8.39343 1.41954C10.5594 0.263576 13.2344 1.60964 13.6936 4.07057C13.8682 5.02103 13.6786 5.90984 13.2793 6.76269C12.8601 7.6515 12.2712 8.41701 11.6124 9.13114C10.4496 10.3899 9.12208 11.4379 7.70472 12.373C7.48513 12.5168 7.29548 12.522 7.07589 12.373C5.53875 11.366 4.1164 10.2255 2.89368 8.83316C2.20995 8.05738 1.61606 7.2148 1.26671 6.2181C0.787598 4.85149 0.94231 3.56708 1.82567 2.41625C2.4495 1.59422 3.29293 1.13184 4.30605 1.02395C5.48884 0.900643 6.50196 1.30138 7.3404 2.15936C7.35537 2.16964 7.36535 2.17478 7.38531 2.19533ZM1.80071 4.93883C1.80071 5.1392 1.8606 5.47828 1.96541 5.81223C2.2349 6.66507 2.719 7.38434 3.28295 8.05224C4.44579 9.4394 5.83819 10.5491 7.31544 11.551C7.37034 11.5869 7.40527 11.5818 7.45518 11.551C8.72781 10.6878 9.93556 9.73225 10.9986 8.59683C11.5925 7.95976 12.1315 7.28159 12.5207 6.48526C12.8601 5.79168 13.0498 5.06727 12.915 4.27607C12.6904 2.93515 11.5575 1.88193 10.235 1.82542C9.19694 1.77918 8.35351 2.19533 7.72468 3.05331C7.52006 3.33075 7.2306 3.33075 7.02099 3.05331C6.30233 2.09771 5.35909 1.6867 4.19126 1.87166C2.82381 2.09258 1.79572 3.3513 1.80071 4.93883Z" fill="#434D56" stroke="#1A2128" stroke-width="0.637934"/>
    </svg>

  </div>
</template>
<script>
export default {
  name: 'HeartIcon',
  props: {

    color: {
      type: String,
      default: ''
    }
  },

}
</script>
<style lang="scss" scoped>
@import "~@/assets/css/variables.scss";
.color-orange-main  {
  svg {
   color: $color-orange-main;
  }
}

</style>